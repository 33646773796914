import { ActionIcon, Divider, Group, Text } from '@mantine/core';
import { SetItemDto } from '../../api/openapi';
import {
  IconArrowDown,
  IconArrowUp,
  IconHeadphones,
  IconMusic,
  IconTrash,
} from '@tabler/icons';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { KEYS_MAP } from '../../constants/keys';

dayjs.extend(duration);
dayjs.extend(relativeTime);

type Props = {
  item: SetItemDto;
  selected?: boolean;
  onClick?: () => void;
  onMoveDown?: () => void;
  onMoveUp?: () => void;
  onDelete?: () => void;
  reorder?: boolean;
};

export const SetItemLine = ({
  item,
  onClick,
  onDelete,
  onMoveDown,
  onMoveUp,
  reorder,
  selected,
}: Props) => {
  const goToYoutube = (videoId: string) => {
    window.open(
      `https://www.youtube.com/watch?v=${videoId}`,
      '_blank',
      'noopener,noreferrer',
    );
  };

  const handleClick = () => {
    if (onClick) onClick();
  };

  return (
    <Group gap={1}>
      {reorder && (
        <>
          <ActionIcon size="xs" variant="subtle" onClick={onMoveUp}>
            <IconArrowUp />
          </ActionIcon>
          <ActionIcon size="xs" variant="subtle" onClick={onMoveDown}>
            <IconArrowDown />
          </ActionIcon>
          <ActionIcon size="xs" variant="subtle" c="red" onClick={onDelete}>
            <IconTrash />
          </ActionIcon>
          <Divider
            orientation="vertical"
            style={{ marginLeft: 6, marginRight: 8 }}
          />
        </>
      )}
      <Group
        gap="xs"
        style={{
          cursor: onClick ? 'pointer' : undefined,
          flex: 1,
        }}
        onClick={handleClick}
      >
        {item.type === 'song' && (
          <>
            <IconMusic />
            <Text fw={selected ? 'bold' : ''} td={selected ? 'underline' : ''}>
              {item.song?.title}
            </Text>
            {item.chosenKey && <Text>({KEYS_MAP[item.chosenKey].label})</Text>}
            {item.song?.youtubeVideoId && (
              <ActionIcon
                size="xs"
                variant="subtle"
                onClick={() =>
                  item.song?.youtubeVideoId
                    ? goToYoutube(item.song?.youtubeVideoId)
                    : undefined
                }
              >
                <IconHeadphones />
              </ActionIcon>
            )}
          </>
        )}
        {item.type !== 'song' && (
          <Text fw={selected ? 'bold' : ''} td={selected ? 'underline' : ''}>
            {item.notes}
          </Text>
        )}
        {item.estimatedTimeSeconds !== undefined &&
          item.estimatedTimeSeconds !== null && (
            <Text c="dimmed" fs="italic" size="sm">
              {dayjs.duration(item.estimatedTimeSeconds, 'seconds').humanize()}
            </Text>
          )}
      </Group>
    </Group>
  );
};
