import { NavLink } from '@mantine/core';
import { IconPinned } from '@tabler/icons';
import { Link, useLocation } from 'react-router-dom';
import { useOpenSongsContext } from '../../context/open-songs.context';

type Props = {
  callback?: () => void;
};

export const PinnedSetLink = ({ callback }: Props) => {
  const { pinnedSet } = useOpenSongsContext();
  const location = useLocation();

  return (
    <NavLink
      label={pinnedSet ? pinnedSet.label : 'No pinned set'}
      leftSection={<IconPinned />}
      component={Link}
      to={`/sets/${pinnedSet?.id}`}
      active={location.pathname === `/sets/${pinnedSet?.id}`}
      disabled={pinnedSet ? false : true}
      onClick={callback}
    />
  );
};
