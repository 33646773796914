import { Group, HoverCard, ActionIcon, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons';

type Props = {
  title: string;
  altTitles: string[];
};

export const SongTitle = ({ altTitles, title }: Props) => {
  return (
    <Group gap={4}>
      <Text size="sm" lineClamp={1}>
        {title}
      </Text>
      {altTitles.length > 0 ? (
        <HoverCard withArrow offset={0}>
          <HoverCard.Target>
            <ActionIcon variant="subtle" size={16}>
              <IconInfoCircle color="gray" size={15} />
            </ActionIcon>
          </HoverCard.Target>
          <HoverCard.Dropdown>
            <Text size="xs" fw="bold">
              Also known as:
            </Text>
            {altTitles.map((title) => (
              <Text key={title} size="xs" fs="italic">
                {title}
              </Text>
            ))}
          </HoverCard.Dropdown>
        </HoverCard>
      ) : null}
    </Group>
  );
};
