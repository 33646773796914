import { Alert, AlertProps } from '@mantine/core';
import { IconAlertOctagon } from '@tabler/icons';

export default function ErrorAlert(props: AlertProps) {
  const { children, ...forwarded } = props;
  return (
    <Alert {...forwarded} icon={<IconAlertOctagon />} color="red">
      {children}
    </Alert>
  );
}
