import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Divider,
  Group,
  LoadingOverlay,
  Space,
  Stack,
  Text,
  Title,
  Tooltip,
} from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import { notifications } from '@mantine/notifications';
import {
  AddSongTagDto,
  SongDto,
  SongDtoPreferredKeyEnum,
  SongFileDto,
  SongFileDtoMusicTypeEnum,
  SongTagDto,
} from '../../api/openapi';
import { AddTagModal } from '../molecules/AddTagModal';
import { EditSongFileDetails } from '../molecules/EditSongFileDetails';
import { KeySelector } from '../molecules/KeySelector';
import useTitle from '../../hooks/use-title';
import { EditSongDetailsModal } from '../molecules/EditSongDetailsModal';
import { useOpenSongsContext } from '../../context/open-songs.context';
import { UploadSongFile } from '../organisms/UploadSongFile';
import { useAuthContext } from '../../context/auth-context';
import { showNotification } from '@mantine/notifications';
import { IconPin } from '@tabler/icons';
import { FileDisplay } from '../molecules/FileDisplay';
import { YoutubeEmbed } from '../atoms/YoutubeEmbed';
import { SongFileList } from '../organisms/SongFileList';
import { useMediaQuery } from '@mantine/hooks';

export const SongPage = () => {
  const params = useParams();
  const auth = useAuthContext();
  const navigate = useNavigate();
  const { openSong, songs } = useOpenSongsContext();
  const [song, setSong] = useState<SongDto>();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  useTitle(song ? song.title : 'Loading...');

  const isMobile = useMediaQuery(`(max-width: 30em)`);
  const [deleteEnabled, setDeleteEnabled] = useState<boolean>(false);
  const [deleteFileEnabled, setDeleteFileEnabled] = useState<boolean>(false);

  // File selection
  const [selectedFile, setSelectedFile] = useState<SongFileDto>();
  const { pinnedSet, refreshPinnedSet } = useOpenSongsContext();

  // Edit file details
  const [editFileKey, setEditFileKey] = useState<SongDtoPreferredKeyEnum>();
  const [editFileType, setEditFileType] = useState<SongFileDtoMusicTypeEnum>();

  // Edit song details
  const [preferredKey, setPreferredKey] = useState<SongDtoPreferredKeyEnum>();
  const [existingTags, setExistingTags] = useState<SongTagDto[]>();

  const handleSetSong = useCallback(
    (newSong: SongDto | undefined) => {
      setSong(newSong);
      setSelectedFile((old) => {
        if (newSong) return newSong.files[0];
        return old;
      });
      if (newSong) openSong(newSong);
    },
    [openSong],
  );

  const handleAddSongToSet = async (song: SongDto) => {
    if (pinnedSet && song.files.length > 0) {
      await SigmaApi.sets.setControllerAddSetItem(pinnedSet.id, {
        chosenKey: song.preferredKey,
        file: song.files[0].id,
        song: song.id,
        type: 'song',
      });
      await refreshPinnedSet();
      showNotification({
        message: `${song.title} was added to ${pinnedSet.label}`,
        title: 'Song added to pinned set',
      });
    }
  };

  const getSong = useCallback(
    async (force = false) => {
      let foundSong: SongDto | undefined;
      if (params.songId) {
        const songId = parseInt(params.songId);
        if (songs && !force) {
          const contextSong = songs.find((s) => s.song.id === songId);
          if (contextSong) {
            foundSong = contextSong.song;
          }
        }
        if (!foundSong) {
          const response = await SigmaApi.songs.songsControllerGetSong(songId);
          foundSong = response.data;
        }
      }
      handleSetSong(foundSong);
      setInitialLoading(false);
    },
    [handleSetSong, params.songId, songs],
  );

  useEffect(() => {
    async function getTags() {
      const response = await SigmaApi.songs.songsControllerGetAllTags();
      setExistingTags(response.data);
    }

    if (!song || (params.songId && song.id !== parseInt(params.songId))) {
      getSong();
    }
    if (!existingTags) {
      getTags();
    }
  }, [params, song, existingTags, songs, openSong, handleSetSong, getSong]);

  useEffect(() => {
    if (
      preferredKey &&
      song &&
      preferredKey !== SongDtoPreferredKeyEnum.Unknown
    ) {
      SigmaApi.songs.songsControllerSetPreferredKeyForSong(song.id, {
        key: preferredKey,
      });
    }
  }, [preferredKey, song]);

  useEffect(() => {
    if (song) {
      setPreferredKey(song.preferredKey);
    }
  }, [song]);

  useEffect(() => {
    setEditFileKey(selectedFile?.key);
    setEditFileType(selectedFile?.musicType);
  }, [selectedFile]);

  const updateFileDetails = async () => {
    if (selectedFile && editFileKey && editFileType) {
      const response = await SigmaApi.songs.songsControllerUpdateSongFile(
        selectedFile.id,
        {
          key: editFileKey,
          musicType: editFileType,
        },
      );
      handleSetSong(response.data);
    }
  };

  return (
    <Stack style={{ maxWidth: 1200, paddingBottom: 32, paddingTop: 16 }}>
      <LoadingOverlay visible={initialLoading} />
      <Group justify="space-between" wrap={isMobile ? 'wrap' : 'nowrap'}>
        <Stack gap={0}>
          <Group>
            <Title order={isMobile ? 2 : 1}>{song && song.title}</Title>
            {song && (
              <EditSongDetailsModal
                primaryTitle={song?.title}
                altTitles={song?.altTitles}
                videoId={song?.youtubeVideoId}
                onSave={async (titles, youtubeVideoId) => {
                  const response =
                    await SigmaApi.songs.songsControllerUpdateTitlesForSong(
                      song.id,
                      titles,
                    );
                  handleSetSong(response.data);
                  if (youtubeVideoId) {
                    const response =
                      await SigmaApi.songs.songsControllerSetYoutubeIdForSong(
                        song.id,
                        youtubeVideoId,
                      );
                    handleSetSong(response.data);
                  }
                }}
              />
            )}
            {song &&
              song.tags.map((tag) => (
                <Badge key={tag.id} color={tag.color}>
                  {tag.name}
                </Badge>
              ))}
            {song && pinnedSet && song.files.length > 0 && (
              <Tooltip label="Add to pinned set">
                <ActionIcon
                  variant="subtle"
                  onClick={() => handleAddSongToSet(song)}
                >
                  <IconPin />
                </ActionIcon>
              </Tooltip>
            )}
            {song && existingTags && (
              <AddTagModal
                onSave={async (tag: AddSongTagDto) => {
                  const response =
                    await SigmaApi.songs.songsControllerAddTagToSong(
                      song.id,
                      tag,
                    );
                  handleSetSong(response.data);
                }}
                tags={existingTags}
              />
            )}
            <Group justify="right" style={{ flex: 1 }}>
              {auth.user && auth.user.isAdmin && song && (
                <Group wrap="nowrap">
                  <Checkbox
                    label="Enable delete"
                    checked={deleteEnabled}
                    onChange={(e) => setDeleteEnabled(e.target.checked)}
                  />
                  <Button
                    color="red"
                    style={{ flexGrow: 1 }}
                    disabled={!deleteEnabled}
                    onClick={async () => {
                      try {
                        await SigmaApi.songs.songsControllerDeleteSong(song.id);
                        // Redirect to songs page
                        navigate('/songs');
                      } catch (err) {
                        notifications.show({
                          color: 'red',
                          message:
                            'This is probably because this song is referenced by a set',
                          title: 'Failed to delete song',
                        });
                      }
                      setDeleteEnabled(false);
                    }}
                  >
                    Delete Song
                  </Button>
                </Group>
              )}
            </Group>
          </Group>
          <Group gap="xs" pt={4}>
            {!isMobile &&
              song &&
              song.altTitles.map((title, index) => (
                <Group key={title}>
                  <Text key={title} c="dimmed">
                    {title}
                  </Text>
                  {index !== song.altTitles.length - 1 && (
                    <Divider orientation="vertical" />
                  )}
                </Group>
              ))}
          </Group>
          <KeySelector
            label="Preferred Key"
            value={preferredKey}
            setValue={setPreferredKey}
          />
        </Stack>
        {song && song.youtubeVideoId && (
          <YoutubeEmbed songName={song.title} embedId={song.youtubeVideoId} />
        )}
      </Group>
      <Space w="md" />
      <Group justify="space-between">
        <Group align="end" gap="sm">
          {song && song.files.length > 0 && (
            <SongFileList
              selectedFile={selectedFile ?? song.files[0]}
              songFiles={song.files}
              onSelectFile={(file) => {
                setSelectedFile(file);
              }}
            />
          )}
          {selectedFile && (
            <EditSongFileDetails
              fileKey={editFileKey}
              setKey={setEditFileKey}
              musicType={editFileType}
              setMusicType={setEditFileType}
              onSave={updateFileDetails}
            />
          )}
        </Group>
        <Group>
          {auth.user && auth.user.isAdmin && selectedFile && (
            <Group wrap="nowrap">
              <Checkbox
                label="Enable delete file"
                checked={deleteFileEnabled}
                onChange={(e) => setDeleteFileEnabled(e.target.checked)}
              />
              <Button
                color="red"
                style={{ flexGrow: 1 }}
                disabled={!deleteFileEnabled}
                onClick={async () => {
                  try {
                    await SigmaApi.songs.songsControllerDeleteSongFile(
                      selectedFile.id,
                    );
                    await getSong(true);
                  } catch (err) {
                    notifications.show({
                      color: 'red',
                      message:
                        'This is probably because this file is referenced by a set',
                      title: 'Failed to delete song file',
                    });
                  }
                  setDeleteFileEnabled(false);
                }}
              >
                Delete File
              </Button>
            </Group>
          )}
          {song && <UploadSongFile song={song} onSuccess={handleSetSong} />}
        </Group>
      </Group>
      {selectedFile && (
        <FileDisplay songFile={selectedFile} songName={song?.title ?? 'Song'} />
      )}
    </Stack>
  );
};
