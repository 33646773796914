import { Stack, StackProps, Table, Space } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { SetDto } from '../../api/openapi';

export const SetList = ({
  sets,
  setsPerPage,
  ...forwarded
}: { sets: SetDto[]; setsPerPage: number } & StackProps) => {
  const notMobile = useMediaQuery('(min-width: 700px)');
  const navigate = useNavigate();
  return (
    <Stack {...forwarded} gap={0}>
      <Table highlightOnHover style={{ ':hover': { cursor: 'pointer' } }}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Name</Table.Th>
            <Table.Th>Songs</Table.Th>
            <Table.Th>Owner</Table.Th>
            <Table.Th>Created Date</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {sets.length === 0 && (
            <Table.Tr>
              <Table.Td style={{ textAlign: 'center' }} colSpan={3}>
                <i>Nothing here... :(</i>
              </Table.Td>
            </Table.Tr>
          )}
          {sets.map((set) => (
            <Table.Tr key={set.id} onClick={() => navigate(`/sets/${set.id}`)}>
              <Table.Td>{set.label}</Table.Td>
              <Table.Td>{set.items.length}</Table.Td>
              <Table.Td>{set.owner.name}</Table.Td>
              <Table.Td>{dayjs(set.createdAt).toString()}</Table.Td>
            </Table.Tr>
          ))}
        </Table.Tbody>
      </Table>
      {notMobile &&
        sets.length < setsPerPage &&
        Array.from({ length: setsPerPage - sets.length }, (v, i) => i).map(
          (x) => <Space key={x} h={40} />,
        )}
    </Stack>
  );
};
