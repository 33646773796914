import {
  Center,
  Divider,
  Group,
  LoadingOverlay,
  Pagination,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { SigmaApi } from '../../api/api';
import { SetCollectionDto, SetDto } from '../../api/openapi';
import Fuse from 'fuse.js';
import { SetsActionBar } from '../molecules/SetsActionBar';
import { SetList } from '../organisms/SetList';
import { SetCollections } from '../organisms/SetCollections';
import { IconSearch } from '@tabler/icons';

const sortSets = (a: SetDto, b: SetDto, sortBy: string): number => {
  if (sortBy === 'title-ascending') {
    return a.label.localeCompare(b.label);
  } else if (sortBy === 'title-descending') {
    return b.label.localeCompare(a.label);
  } else {
    return 0;
  }
};

export const SetsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [layout] = useState<'list' | 'cards'>('list');
  const [search, setSearch] = useState<string>('');
  const [searchCollections, setSearchCollections] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('title-ascending');

  const [sets, setSets] = useState<SetDto[]>([]);
  const [filteredSets, setFilteredSets] = useState<SetDto[]>([]);
  const [pageSets, setPageSets] = useState<SetDto[]>([]);

  // Collections
  const [collections, setCollections] = useState<SetCollectionDto[]>([]);
  const [filteredCollections, setFilteredCollections] = useState<
    SetCollectionDto[]
  >([]);

  // Pagination settings
  const [page, setPage] = useState<number>(1);
  const [setsPerPage, setSetsPerPage] = useState<number>(12);

  const onMobile = useMediaQuery('(max-width: 700px)');

  const numPages = Math.ceil(filteredSets.length / setsPerPage);

  useEffect(() => {
    async function getSets() {
      setLoading(true);
      const response = await SigmaApi.sets.setControllerListSets(false);
      setSets(response.data);
      setLoading(false);
    }

    getSets();
  }, []);

  useEffect(() => {
    async function getCollections() {
      setLoading(true);
      const response = await SigmaApi.sets.setControllerGetSetCollections(true);
      setCollections(response.data);
      setLoading(false);
    }

    getCollections();
  }, []);

  useEffect(() => {
    if (page > Math.ceil(filteredSets.length / setsPerPage)) {
      setPage(Math.ceil(filteredSets.length / setsPerPage));
    } else if (page < 1) {
      setPage(1);
    }
  }, [page, filteredSets, setsPerPage]);

  useEffect(() => {
    if (search.length > 0) {
      setSortBy('');
    } else if (sortBy === '') {
      setSortBy('title-ascending');
    }
  }, [search, sortBy]);

  useEffect(() => {
    const fuseSearch = new Fuse(sets, {
      keys: ['label'],
    });
    const filtered =
      search.length > 0
        ? fuseSearch.search(search).map((result) => result.item)
        : sets;
    setFilteredSets(filtered);

    const sorted = [...filtered].sort((a, b) => sortSets(a, b, sortBy));
    setPageSets(sorted.slice((page - 1) * setsPerPage, page * setsPerPage));
  }, [sets, page, setsPerPage, search, sortBy]);

  useEffect(() => {
    const fuseSearch = new Fuse(collections, {
      keys: ['label'],
    });
    const filtered =
      searchCollections.length > 0
        ? fuseSearch.search(searchCollections).map((result) => result.item)
        : collections;
    setFilteredCollections(filtered);
  }, [collections, searchCollections]);

  useEffect(() => {
    setSetsPerPage(onMobile && layout === 'cards' ? 4 : 12);
  }, [layout, onMobile]);

  return (
    <>
      <LoadingOverlay visible={loading} />
      <Stack>
        <Group justify="space-between">
          <Title>Collections</Title>
          <TextInput
            placeholder="Search..."
            leftSection={<IconSearch />}
            value={searchCollections}
            onChange={(e) => setSearchCollections(e.target.value)}
            style={{ ':focus': { flexGrow: 1 } }}
          />
        </Group>
        <SetCollections collections={filteredCollections} />
        <Divider />
        <Title>All Sets</Title>
        <SetsActionBar
          search={search}
          setSearch={setSearch}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
        <SetList sets={pageSets} setsPerPage={setsPerPage} />
        <Center>
          <Pagination
            value={page}
            onChange={setPage}
            disabled={numPages === 0}
            total={numPages}
            size={onMobile ? 'sm' : 'md'}
          />
        </Center>
      </Stack>
    </>
  );
};
