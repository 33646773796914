import {
  Stack,
  Group,
  ActionIcon,
  Divider,
  Box,
  useComputedColorScheme,
} from '@mantine/core';
import { IconDownload, IconMaximize } from '@tabler/icons';
import { ReactInstance, useRef } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { SongFileDto } from '../../api/openapi';
import { KeySwatch } from '../atoms/KeySwatch';
import { MusicTypeSwatch } from '../atoms/MusicTypeSwatch';
import { ChordProDisplay } from './ChordProDisplay';
import { PdfDisplay } from './PdfDisplay';
import { SigmaApi } from '../../api/api';
import ReactToPrint from 'react-to-print';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  songName: string;
  songFile: SongFileDto;
  onLoadSuccess?: () => void;
};

export const FileDisplay = (props: Props) => {
  const fullScreenHandle = useFullScreenHandle();
  const isTablet = useMediaQuery(`(max-width: 62em)`);
  const isMobile = useMediaQuery(`(max-width: 30em)`);

  const displayRef = useRef<ReactInstance>(null);
  const colorScheme = useComputedColorScheme('light');

  let display = <>No preview available for this file type</>;
  if (props.songFile.name.endsWith('pdf')) {
    display = (
      <PdfDisplay
        songFile={props.songFile}
        onLoadSuccess={props.onLoadSuccess}
      />
    );
  } else if (props.songFile.name.endsWith('txt')) {
    display = <ChordProDisplay ref={displayRef} songFile={props.songFile} />;
  }

  return (
    <FullScreen handle={fullScreenHandle}>
      <Stack
        style={{
          backgroundColor: fullScreenHandle.active
            ? colorScheme === 'dark'
              ? '#1a1b1e'
              : 'whitesmoke'
            : undefined,
          flex: 1,
          height: fullScreenHandle.active ? '100%' : undefined,
          minHeight: fullScreenHandle.active ? '100vh' : undefined,
          overflow: fullScreenHandle.active ? 'auto' : undefined,
          position: 'relative',
        }}
      >
        <Stack
          gap={8}
          style={{
            backgroundColor: colorScheme === 'dark' ? '#1a1b1e' : 'white',
            marginLeft: isTablet && !fullScreenHandle.active ? 40 : 0,
            paddingBottom: 3,
            paddingTop: fullScreenHandle.active ? 10 : isMobile ? 13 : 9,
            position: 'sticky',
            top: 0,
            zIndex: 100,
          }}
        >
          <Group
            justify="space-between"
            style={{
              paddingLeft: fullScreenHandle.active ? 10 : undefined,
              paddingRight: fullScreenHandle.active ? 10 : undefined,
            }}
          >
            <Group align="center" justify="right">
              <MusicTypeSwatch songType={props.songFile.musicType} />
              <KeySwatch songKey={props.songFile.key} />
              {!isMobile && (
                <p
                  style={{
                    fontSize: 10,
                    height: 20,
                    maxWidth: 160,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.songFile.name}
                </p>
              )}
            </Group>
            <Group align="center" justify="left">
              {props.songFile.name.endsWith('pdf') ? (
                <a // This download button works in production but not in development
                  href={`${SigmaApi.baseURL}/api/songs/files/${props.songFile.id}`}
                  download={`${props.songName}.pdf`}
                >
                  <ActionIcon variant="subtle">
                    <IconDownload />
                  </ActionIcon>
                </a>
              ) : (
                <ReactToPrint
                  trigger={() => {
                    return (
                      <ActionIcon variant="subtle">
                        <IconDownload />
                      </ActionIcon>
                    );
                  }}
                  content={() => displayRef.current}
                />
              )}
              <ActionIcon
                variant="subtle"
                style={{ float: 'right' }}
                onClick={
                  fullScreenHandle.active
                    ? fullScreenHandle.exit
                    : fullScreenHandle.enter
                }
              >
                <IconMaximize />
              </ActionIcon>
            </Group>
          </Group>
          {fullScreenHandle.active && <Divider />}
        </Stack>
        <Box
          style={{
            paddingBottom: fullScreenHandle.active ? 16 : undefined,
            paddingLeft: fullScreenHandle.active ? 10 : undefined,
            paddingRight: fullScreenHandle.active ? 10 : undefined,
          }}
        >
          {display}
        </Box>
      </Stack>
    </FullScreen>
  );
};
