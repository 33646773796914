import {
  Center,
  Group,
  SegmentedControl,
  Select,
  Space,
  TextInput,
  useComputedColorScheme,
} from '@mantine/core';
import {
  IconSearch,
  IconSortAscendingLetters,
  IconSortDescendingLetters,
} from '@tabler/icons';

type Props = {
  search: string;
  setSearch: (x: string) => void;
  sortBy: string;
  setSortBy: (x: string) => void;
  pageSize?: number;
  setPageSize?: (x: number) => void;
};

export const SongsActionBar = ({
  pageSize,
  search,
  setPageSize,
  setSearch,
  setSortBy,
  sortBy,
}: Props) => {
  const scheme = useComputedColorScheme('light');
  return (
    <Group
      justify="space-between"
      style={{
        backgroundColor: scheme === 'light' ? 'white' : '#1a1b1e',
        position: 'sticky',
        top: 8,
        zIndex: 100,
      }}
    >
      <Space visibleFrom="md" w="60" />
      <TextInput
        placeholder="Search by title, tags..."
        leftSection={<IconSearch />}
        defaultValue={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ flex: 1, maxWidth: 1000, minWidth: 200 }}
      />
      <Group>
        <SegmentedControl
          color="green"
          onChange={setSortBy}
          value={sortBy}
          disabled={search.length > 0}
          data={[
            {
              label: (
                <Center>
                  <IconSortAscendingLetters />
                </Center>
              ),
              value: 'title-ascending',
            },
            {
              label: (
                <Center>
                  <IconSortDescendingLetters />
                </Center>
              ),
              value: 'title-descending',
            },
          ]}
        />
        {pageSize && setPageSize && (
          <Select
            data={['10', '15', '25', '50']}
            value={pageSize.toString()}
            onChange={(value) =>
              value ? setPageSize(Number.parseInt(value)) : undefined
            }
            style={{ width: '70px' }}
          />
        )}
      </Group>
    </Group>
  );
};
