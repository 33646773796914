import { NavLink, Transition } from '@mantine/core';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { PRIVATE_ROUTES } from '../../routes';

export const NavLinks = ({
  basePath = '/',
  callback,
  depth = 0,
  isAdmin,
  routes,
}: {
  basePath?: string;
  routes: typeof PRIVATE_ROUTES;
  isAdmin: boolean;
  callback?: () => void;
  depth?: number;
}) => {
  const location = useLocation();
  return (
    <>
      {routes
        .filter(
          (route) =>
            (route.menu || route.menuOnSelect) &&
            ((route.admin && isAdmin) || !route.admin),
        )
        .map((route) => {
          const pathMatch = matchPath(basePath + route.path, location.pathname);
          const paramCount = pathMatch
            ? Object.entries(pathMatch.params).length
            : 0;
          const isIgnoredRoute =
            pathMatch && pathMatch.params && route.ignoreParamMatch
              ? Object.values(pathMatch.params).find(
                  (param) =>
                    route.ignoreParamMatch &&
                    route.ignoreParamMatch.includes(param ?? ''),
                )
              : true;
          const matches =
            pathMatch &&
            ((paramCount && route.hasParam && !isIgnoredRoute) ||
              paramCount === 0)
              ? true
              : false;

          return (
            <Transition
              mounted={route.menu || ((route.menuOnSelect ?? false) && matches)}
              transition="slide-right"
              duration={400}
              timingFunction="ease"
              key={route.path}
            >
              {(styles) => (
                <div key={route.path} style={styles}>
                  <NavLink
                    style={{
                      paddingLeft: 12 + depth * 20,
                      pointerEvents: route.hasParam ? 'none' : 'all',
                    }}
                    key={route.path}
                    label={
                      route.useTitle
                        ? document.title.split(' | ')[0]
                        : route.label
                    }
                    leftSection={route.icon}
                    component={Link}
                    to={basePath + route.path}
                    active={
                      route.path === ''
                        ? matches
                        : location.pathname.includes(basePath + route.path) ||
                          matches
                    }
                    variant={matches ? 'light' : 'subtle'}
                    onClick={callback}
                  ></NavLink>
                  {route.children && (
                    <div style={styles}>
                      <NavLinks
                        basePath={basePath + route.path + '/'}
                        routes={route.children}
                        isAdmin={isAdmin}
                        callback={callback}
                        depth={depth + 1}
                      />
                    </div>
                  )}
                </div>
              )}
            </Transition>
          );
        })}
    </>
  );
};
