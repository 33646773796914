import { pdfjs, Document, Page } from 'react-pdf';
import { Skeleton, Stack } from '@mantine/core';
import { SongFileDto } from '../../api/openapi';
import { SigmaApi } from '../../api/api';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { useState } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
  songFile: SongFileDto;
  onLoadSuccess?: () => void;
};

export const PdfDisplay = (props: Props) => {
  const width = Math.min(1000, window.outerWidth - 32);

  const [numPages, setNumPages] = useState(0);

  return (
    <Document
      file={`${SigmaApi.baseURL}/api/songs/files/${props.songFile.id}`}
      onLoadSuccess={({ numPages }) => {
        setNumPages(numPages);
        if (props.onLoadSuccess) props.onLoadSuccess();
      }}
      loading={<Skeleton height={width * 1.44} width={width} />}
    >
      <Stack gap={0} align="center">
        {Array.from(Array(numPages).keys()).map((index) => (
          <Page
            pageIndex={index}
            width={width}
            loading={<Skeleton height={width * 1.44} width={width} />}
          />
        ))}
      </Stack>
    </Document>
  );
};
