import {
  Button,
  Container,
  LoadingOverlay,
  Stack,
  TextInput,
  Title,
  UnstyledButton,
} from '@mantine/core';
import { hideNotification, showNotification } from '@mantine/notifications';
import { IconMusic } from '@tabler/icons';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import { CreateSetItemDto } from '../../api/openapi';
import ErrorAlert from '../atoms/ErrorAlert';

export const CreateSetPage = () => {
  const [label, setLabel] = useState<string>('');
  const [items, setItems] = useState<CreateSetItemDto[]>([]);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const createSet: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(undefined);
    try {
      const response = await SigmaApi.sets.setControllerCreateSet({
        items,
        label,
      });

      setLabel('');
      setItems([]);
      const set = response.data;
      showNotification({
        autoClose: 1000 * 10, // 10 seconds
        icon: <IconMusic size={20} />,
        id: `create-${set.id}`,
        message: 'Click above to view the set',
        title: (
          <UnstyledButton
            onClick={() => {
              hideNotification(`create-${set.id}`);
              navigate(`/sets/${set.id}`);
            }}
          >
            Set {set.label} created
          </UnstyledButton>
        ),
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(`Error: ${err.response?.data}`);
      }
    }
    setLoading(false);
  };

  return (
    <Container size="md">
      <LoadingOverlay visible={loading} />
      <form onSubmit={createSet}>
        <Stack>
          <Title order={4}>Create a new set</Title>
          <Stack>
            <TextInput
              required
              label="Label"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
            <Button type="submit">Create Set</Button>
            {error && <ErrorAlert>{error}</ErrorAlert>}
          </Stack>
        </Stack>
      </form>
    </Container>
  );
};
