import {
  Container,
  Group,
  LoadingOverlay,
  Title,
  Text,
  Space,
  Stack,
  Select,
  Checkbox,
  Slider,
  MantineFontSize,
  Divider,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ReactNode, forwardRef, useEffect, useState } from 'react';
import { SigmaApi } from '../../api/api';
import { SongFileDto, SongKeyDtoKeyEnum } from '../../api/openapi';
import { KEYS, KEYS_IN_ORDER } from '../../constants/keys';
import { ChordChip } from '../atoms/ChordChip';
import classes from './ChordProDisplay.module.css';

type Props = {
  songFile: SongFileDto;
};

const sharpScale = [
  'C',
  'C#',
  'D',
  'D#',
  'E',
  'F',
  'F#',
  'G',
  'G#',
  'A',
  'A#',
  'B',
];
const normalizeFlatToSharp: { [x: string]: string } = {
  Ab: 'G#',
  'B#': 'C',
  Bb: 'A#',
  Cb: 'B',
  Db: 'C#',
  'E#': 'F',
  Eb: 'D#',
  Fb: 'E',
  Gb: 'F#',
};
const flatScale = [
  'C',
  'Db',
  'D',
  'Eb',
  'E',
  'F',
  'Gb',
  'G',
  'Ab',
  'A',
  'Bb',
  'B',
];
const normalizeSharpToFlat: { [x: string]: string } = {
  'A#': 'Bb',
  'B#': 'C',
  'C#': 'Db',
  Cb: 'B',
  'D#': 'Eb',
  'E#': 'F',
  'F#': 'Gb',
  Fb: 'E',
  'G#': 'Ab',
};

const preferFlats: SongKeyDtoKeyEnum[] = [
  ...Object.values(SongKeyDtoKeyEnum).filter((key) => key.includes('Flat')),
  SongKeyDtoKeyEnum.F,
];

function transposeChord(
  chord: string,
  originalKey: SongKeyDtoKeyEnum,
  targetKey: SongKeyDtoKeyEnum,
) {
  const offset =
    KEYS_IN_ORDER.findIndex((keys) => keys.includes(targetKey)) -
    KEYS_IN_ORDER.findIndex((keys) => keys.includes(originalKey));
  return chord.replace(/[CDEFGAB](b|#)?/g, function (match) {
    if (preferFlats.includes(targetKey)) {
      const i =
        (flatScale.indexOf(
          normalizeSharpToFlat[match] ? normalizeSharpToFlat[match] : match,
        ) +
          offset) %
        flatScale.length;
      return flatScale[i < 0 ? i + flatScale.length : i];
    } else {
      const i =
        (sharpScale.indexOf(
          normalizeFlatToSharp[match] ? normalizeFlatToSharp[match] : match,
        ) +
          offset) %
        sharpScale.length;
      return sharpScale[i < 0 ? i + sharpScale.length : i];
    }
  });
}

function processChordProString(
  content: string,
  fontSize: MantineFontSize,
  originalKey: SongKeyDtoKeyEnum,
  targetKey: SongKeyDtoKeyEnum,
): {
  elements: ReactNode[];
  contents: { [x: string]: string };
} {
  const columns: ReactNode[] = [];
  let elements: ReactNode[] = [];
  const contents: { [x: string]: string } = {};

  let chords: ReactNode[] = [];
  let main: ReactNode[] = [];

  let in_comment = false;
  let in_comment_text = false;
  let in_chord = false;
  let comment_type = '';

  let general = '';
  let context = '';

  let paragraphBreak = false;
  let notComment = true;
  let isLineStart = true;
  let lineHasChord = false;

  const lineHeight = 0.5;
  for (const c of content) {
    switch (c) {
      case '{':
        in_comment = true;
        in_comment_text = false;
        comment_type = '';
        if (general.trim().length > 0) {
          main.push(
            <td style={{ whiteSpace: 'nowrap' }} key={Math.random()}>
              <Text size={fontSize} lh={lineHeight}>
                {general}
              </Text>
            </td>,
          );
        }
        context = '';
        break;
      case '}':
        in_comment = false;
        general = '';
        if (comment_type === 'comment') {
          elements.push(
            <Text fw={700} size={fontSize} key={Math.random()}>
              {context}
            </Text>,
          );
        } else {
          contents[comment_type] = context;
        }
        notComment = false;
        break;
      case '[':
        in_chord = true;
        if (general.trim().length > 0 || lineHasChord) {
          main.push(
            <td style={{ whiteSpace: 'nowrap' }} key={Math.random()}>
              <Text size={fontSize} lh={lineHeight}>
                {general}
              </Text>
            </td>,
          );
        }
        context = '';
        break;
      case ']':
        in_chord = false;
        general = '';
        chords.push(
          <td style={{ whiteSpace: 'nowrap' }} key={Math.random()}>
            <ChordChip
              size={fontSize}
              chord={transposeChord(context, originalKey, targetKey)}
            />
          </td>,
        );
        lineHasChord = true;
        break;
      case '\n':
        // New line
        if (general.trim().length > 0 || lineHasChord) {
          main.push(
            <td key={Math.random()} style={{ whiteSpace: 'nowrap' }}>
              <Text size={fontSize} lh={lineHeight}>
                {general}
              </Text>
            </td>,
          );
        }
        if (main.length === 0 && !lineHasChord && notComment) {
          paragraphBreak = true;
        } else if (lineHasChord) {
          elements.push(
            <table key={Math.random()}>
              <tbody>
                <tr style={{ lineHeight: 0.9 }}>{chords}</tr>
                <tr style={{ lineHeight: 0.9 }}>{main}</tr>
              </tbody>
            </table>,
          );
        } else if (main.length > 0) {
          elements.push(
            <table>
              <tbody>
                <tr>{main}</tr>
              </tbody>
            </table>,
          );
        }
        chords = [];
        main = [];
        general = '';
        context = '';
        in_comment = false;
        in_chord = false;
        lineHasChord = false;

        if (paragraphBreak && elements.length > 0) {
          columns.push(
            <Stack
              gap={1}
              key={Math.random()}
              className={classes.avoidPageBreak}
            >
              {elements}
            </Stack>,
          );
          elements = [];
        }
        paragraphBreak = false;
        notComment = true;
        break;
      default:
        if (isLineStart) {
          chords.push(<td key={Math.random()}></td>);
        }
        if (in_comment && !in_comment_text) {
          if (c === ':') {
            in_comment_text = true;
          } else {
            comment_type += c;
          }
        } else if (in_chord || in_comment) {
          context += c;
        } else {
          general += c;
        }
    }

    if (c === '\n') {
      isLineStart = true;
    } else {
      isLineStart = false;
    }

    if (general === 'CCLI Song #') {
      break;
    }
  }

  return { contents, elements: columns };
}

export const ChordProDisplay = forwardRef(({ songFile }: Props, ref) => {
  const [stringContent, setStringContent] = useState<string>();
  const [content, setContent] = useState<ReactNode>();
  const [metadata, setMetadata] = useState<{ [x: string]: string }>({});

  const isTablet = useMediaQuery(`(max-width: 62em)`);
  const isMobile = useMediaQuery(`(max-width: 30em)`);
  const [maxHeight, setMaxHeight] = useState<number>(100);
  const [columns, setColumns] = useState<boolean>(!isTablet);
  const [targetKey, setTargetKey] = useState<SongKeyDtoKeyEnum>(songFile.key);
  const [fontSize, setFontSize] = useState<MantineFontSize>(
    isMobile ? 'xs' : 'sm',
  );

  useEffect(() => {
    setColumns(!isTablet);
  }, [isTablet]);

  useEffect(() => {
    const getContent = async () => {
      const file = await SigmaApi.songs.songsControllerGetSongFile(songFile.id);
      const fileAsString = file.data as unknown as string;
      setStringContent(fileAsString);
    };
    getContent();
  }, [songFile]);

  useEffect(() => {
    if (stringContent) {
      const processed = processChordProString(
        stringContent,
        fontSize,
        songFile.key,
        targetKey,
      );
      setContent(
        <Stack
          align="flex-start"
          style={{
            flexWrap: 'wrap',
            maxHeight: columns ? `calc(${maxHeight}vh - 200px)` : '',
          }}
        >
          {processed.elements}
        </Stack>,
      );
      setMetadata(processed.contents);
    }
  }, [stringContent, targetKey, fontSize, columns, maxHeight, songFile.key]);

  const transposeKey = metadata.key
    ? transposeChord(metadata.key, songFile.key, targetKey)
    : '';
  return (
    <Container
      ref={ref as any}
      style={{
        padding: isMobile ? 0 : undefined,
      }}
    >
      <LoadingOverlay visible={content ? false : true} />
      <Group align="flex-start" justify="space-between">
        <Stack gap={0}>
          <Group>
            <Title order={3}>{metadata.title}</Title>
            {metadata.key && (
              <Text>
                Key:
                {transposeChord(metadata.key, songFile.key, targetKey)}
                {transposeKey === metadata.key && ' (Original)'}
              </Text>
            )}
            {metadata.time && (
              <Text>
                Time:
                {metadata.time}
              </Text>
            )}
          </Group>
          <Group>
            {metadata.ccli && <Text fz="sm">CCLI Song #{metadata.ccli}</Text>}
            {metadata.ccli_license && (
              <Text fz="sm">License #{metadata.ccli_license}</Text>
            )}
          </Group>
          <Group gap="xs">
            <Text fz="sm" c="dimmed">
              {metadata.artist}
            </Text>
          </Group>
        </Stack>
        <Group align="flex-start" className={classes.noprint}>
          <Stack className={classes.noprint}>
            <Checkbox
              label="Columns"
              checked={columns}
              onChange={(event) => setColumns(event.target.checked)}
              className={classes.noprint}
            />
            <Slider
              label="Max height"
              value={maxHeight}
              onChange={(value) => setMaxHeight(value)}
              min={100}
              max={200}
              step={25}
              marks={[
                { label: '100%', value: 100 },
                { label: '150%', value: 150 },
                { label: '200%', value: 200 },
              ]}
              disabled={!columns}
              className={classes.noprint}
            />
          </Stack>
          <Select
            label="Font size"
            data={[
              { label: 'XS', value: 'xs' },
              { label: 'S', value: 'sm' },
              { label: 'M', value: 'md' },
              { label: 'L', value: 'lg' },
              { label: 'XL', value: 'xl' },
            ]}
            value={fontSize.toString()}
            onChange={(value) => (value ? setFontSize(value) : undefined)}
            style={{ width: '80px' }}
            className={classes.noprint}
          />
          <Select
            label="Key"
            data={KEYS.filter(
              (key) =>
                key.value !== SongKeyDtoKeyEnum.Unknown &&
                key.value !== SongKeyDtoKeyEnum.CFlat &&
                key.value !== SongKeyDtoKeyEnum.BSharp &&
                key.value !== SongKeyDtoKeyEnum.ESharp &&
                key.value !== SongKeyDtoKeyEnum.FFlat,
            )}
            value={targetKey}
            onChange={(value) => setTargetKey(value as SongKeyDtoKeyEnum)}
            style={{ width: '80px' }}
            disabled={songFile.key === SongKeyDtoKeyEnum.Unknown}
            className={classes.noprint}
          />
        </Group>
      </Group>
      <Space h="lg" />
      {content}
      <Space h="lg" />
      <Divider variant="dashed" />
      <Space h="sm" />
      {metadata.copyright && <Text fz="xs">&copy; {metadata.copyright}</Text>}
      {metadata.footer && (
        <Text fz="xs" fs="italic">
          {metadata.footer}
        </Text>
      )}
    </Container>
  );
});
