import React from 'react';
import { IconFileMusic } from '@tabler/icons';
import { SongFileDtoMusicTypeEnum } from '../../api/openapi';
import { Swatch } from './Swatch';
import { MUSIC_TYPES_MAP } from '../../constants/music-type';

interface Props {
  songType: SongFileDtoMusicTypeEnum;
}

export const MusicTypeSwatch = ({ songType }: Props) => {
  const displayMusicType = MUSIC_TYPES_MAP[songType];
  return (
    <Swatch
      tooltip="Music type"
      icon={<IconFileMusic />}
      label={displayMusicType.label}
      color={displayMusicType.color}
    />
  );
};
