import {
  Center,
  Container,
  Group,
  LoadingOverlay,
  Pagination,
  Stack,
  Text,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { SigmaApi } from '../../api/api';
import { SongDto } from '../../api/openapi';
import { SongsActionBar } from '../molecules/SongsActionBar';
import { SongList } from '../organisms/SongList';
import Fuse from 'fuse.js';
import { useDebouncedState, useMediaQuery } from '@mantine/hooks';

const sortSongs = (a: SongDto, b: SongDto, sortBy: string): number => {
  if (sortBy === 'title-ascending') {
    return a.title.localeCompare(b.title);
  } else if (sortBy === 'title-descending') {
    return b.title.localeCompare(a.title);
  } else if (sortBy === 'key-ascending') {
    return a.preferredKey.localeCompare(b.preferredKey);
  } else if (sortBy === 'key-descending') {
    return b.preferredKey.localeCompare(a.preferredKey);
  } else {
    return 0;
  }
};

export const SongsPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useDebouncedState('', 200, { leading: true });
  const [sortBy, setSortBy] = useState<string>('title-ascending');
  const [pageSize, setPageSize] = useState(10);

  const [songs, setSongs] = useState<SongDto[]>([]);
  const [filteredSongs, setFilteredSongs] = useState<SongDto[]>([]);
  const [sortedSongs, setSortedSongs] = useState<SongDto[]>([]);
  const [pageSongs, setPageSongs] = useState<SongDto[]>([]);

  const onMobile = useMediaQuery('(max-width: 700px)');
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);

  useEffect(() => {
    setPageSongs(sortedSongs.slice(0 + (page - 1) * pageSize, pageSize * page));
    setNumPages(Math.ceil(sortedSongs.length / pageSize));
  }, [sortedSongs, pageSize, page]);

  useEffect(() => {
    async function getSongs() {
      setLoading(true);
      const response = await SigmaApi.songs.songsControllerListSongs();
      setSongs(response.data);
      setLoading(false);
    }

    getSongs();
  }, []);

  useEffect(() => {
    if (search.length > 0) {
      setSortBy('');
    } else if (sortBy === '') {
      setSortBy('title-ascending');
    }
  }, [search, sortBy]);

  useEffect(() => {
    const fuseSearch = new Fuse(songs, {
      keys: ['title', 'altTitles', 'tags.name'],
    });
    const filtered =
      search.length > 0
        ? fuseSearch.search(search).map((result) => result.item)
        : songs;
    setFilteredSongs(filtered);

    const sorted = [...filtered].sort((a, b) => sortSongs(a, b, sortBy));
    setSortedSongs(sorted);
  }, [songs, search, sortBy]);

  return (
    <Container size={1000}>
      <LoadingOverlay visible={loading} />
      <Stack>
        <SongsActionBar
          search={search}
          setSearch={setSearch}
          sortBy={sortBy}
          setSortBy={setSortBy}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
        <Group justify="space-between">
          <Text size="xs">
            Current filters show {filteredSongs.length} songs
          </Text>
          <Text size="xs">Library contains {songs.length} songs</Text>
        </Group>
        <SongList songs={pageSongs} />
        <Center>
          <Pagination
            value={page}
            onChange={setPage}
            disabled={numPages === 0}
            total={numPages}
            size={onMobile ? 'sm' : 'md'}
          />
        </Center>
      </Stack>
    </Container>
  );
};
