import {
  AdminApi,
  Configuration,
  UsersApi,
  AuthApi,
  SongsApi,
  SetsApi,
} from './openapi';

const API_BASE_URL = window.location.origin;
const SigmaConfiguration = new Configuration({ basePath: API_BASE_URL });

export const SigmaApi = {
  admin: new AdminApi(SigmaConfiguration),
  auth: new AuthApi(SigmaConfiguration),
  baseURL: API_BASE_URL,
  sets: new SetsApi(SigmaConfiguration),
  songs: new SongsApi(SigmaConfiguration),
  users: new UsersApi(SigmaConfiguration),
};
