import {
  Autocomplete,
  Badge,
  Button,
  Modal,
  Stack,
  UnstyledButton,
} from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { AddSongTagDto, SongTagDto } from '../../api/openapi';
import { ColorSelect } from '../atoms/ColorSelect';

type Props = {
  onSave: (tag: AddSongTagDto) => any;
  tags: SongTagDto[];
  onOpen?: () => void;
  onClose?: () => void;
};

export const AddTagModal = (props: Props) => {
  const [opened, setOpened] = useState(false);

  const [tag, setTag] = useState<string>();
  const [color, setColor] = useState<string>();

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (tag) {
      props.onSave({ color: color ? color : '', name: tag });
      handleClose();
    }
  };

  const handleClose = () => {
    setOpened(false);
    if (props.onClose) props.onClose();
  };

  useEffect(() => {
    if (tag) {
      const tagObj = props.tags.find((t) => t.name === tag);
      setColor(tagObj?.color);
    }
  }, [tag, props.tags]);

  return (
    <>
      <Modal opened={opened} onClose={handleClose} title="Tag this song!">
        <form onSubmit={handleSubmit}>
          <Stack>
            <Autocomplete
              label="Tag Name"
              placeholder="Enter the tag you want here!"
              data={Array.from(new Set(props.tags.map((tag) => tag.name)))}
              value={tag}
              onChange={setTag}
              required
            />
            <ColorSelect
              label="Tag Colour (Optional)"
              value={color}
              setValue={setColor}
            />
            <Button type="submit" fullWidth>
              Add Tag
            </Button>
          </Stack>
        </form>
      </Modal>

      <UnstyledButton
        onClick={() => {
          setTag(undefined);
          setColor(undefined);
          setOpened(true);
          if (props.onOpen) props.onOpen();
        }}
      >
        <Badge
          variant="outline"
          style={{ paddingLeft: 5 }}
          leftSection={<IconPlus size={12} height={10} />}
        >
          Add Tag
        </Badge>
      </UnstyledButton>
    </>
  );
};
