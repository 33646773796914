import {
  Alert,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
} from '@mantine/core';
import { useState } from 'react';
import { SigmaApi } from '../../api/api';
import ErrorAlert from '../atoms/ErrorAlert';

type Props = {
  onSuccess?: () => void;
};

export default function UserCreation({ onSuccess }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const clearFields = () => {
    setName('');
    setEmail('');
    setUsername('');
    setPassword('');
    setIsAdmin(false);
  };

  const createUser: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    try {
      await SigmaApi.auth.authControllerRegisterNewUser({
        email,
        isAdmin,
        name,
        password,
        username,
      });
      setSuccess(true);
      if (onSuccess) onSuccess();
      clearFields();
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={createUser}>
      <Stack>
        <Group grow>
          <TextInput
            label="Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            label="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            label="Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextInput
            label="Password"
            type="password"
            autoComplete="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Group>
        <Group justify="right">
          {success && <Alert style={{ flexGrow: 1 }}>User created</Alert>}
          {error && (
            <ErrorAlert style={{ flexGrow: 1 }}>
              Failed to create user
            </ErrorAlert>
          )}
          <Checkbox
            label="Is Admin?"
            labelPosition="left"
            checked={isAdmin}
            onChange={(e) => setIsAdmin(e.target.checked)}
          />
          <Button loading={loading} type="submit">
            Create User
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
