import {
  Button,
  ButtonVariant,
  DefaultMantineColor,
  Group,
  Modal,
} from '@mantine/core';
import { useState } from 'react';

type Props = {
  label: string;
  message: string;
  color?: DefaultMantineColor;
  variant?: ButtonVariant;
  onConfirm?: () => void;
};

export const ConfirmButton = ({
  color,
  label,
  message,
  onConfirm,
  variant,
}: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button color={color} variant={variant} onClick={() => setOpen(true)}>
        {label}
      </Button>
      <Modal opened={open} onClose={() => setOpen(false)} title={message}>
        <Group justify="space-between">
          <Button onClick={() => setOpen(false)} variant="subtle">
            Cancel
          </Button>
          <Button onClick={onConfirm} color={color} variant="filled">
            {label}
          </Button>
        </Group>
      </Modal>
    </>
  );
};
