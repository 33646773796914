import { useCallback, useState } from 'react';
import { SetDto, SongDto } from '../api/openapi';
import dayjs from 'dayjs';
import { SigmaApi } from '../api/api';

const MAX_OPEN_SONGS = 5;

export const useOpenSongs = () => {
  const [pinnedSet, setPinnedSet] = useState<SetDto>();
  const [songs, setSongs] = useState<{ song: SongDto; last: Date }[]>([]);

  const openSong = useCallback((song: SongDto) => {
    setSongs((old) => {
      const existingIndex = old.findIndex((o) => o.song.id === song.id);
      if (existingIndex >= 0) {
        old[existingIndex] = { last: new Date(), song };
        return [...old];
      } else if (old.length >= MAX_OPEN_SONGS) {
        // remove song that has been there the longest
        let oldestLast = new Date();
        let oldestIndex = 0;
        old.forEach((o, i) => {
          if (dayjs(o.last).isBefore(oldestLast)) {
            oldestLast = o.last;
            oldestIndex = i;
          }
        });
        old.splice(oldestIndex, 1);
      }
      return [...old, { last: new Date(), song }];
    });
  }, []);

  const viewSong = useCallback((songId: number) => {
    setSongs((old) => {
      return old.map((o) =>
        o.song.id === songId ? { last: new Date(), song: o.song } : o,
      );
    });
  }, []);

  const refreshPinnedSet = useCallback(async () => {
    if (pinnedSet) {
      const response = await SigmaApi.sets.setControllerGetSet(pinnedSet.id);
      setPinnedSet(response.data);
    }
  }, [pinnedSet]);

  const setPinnedSetWithLog = useCallback(
    (set: SetDto | undefined) => {
      console.log('Updating pinned set');
      setPinnedSet(set);
    },
    [setPinnedSet],
  );

  return {
    openSong,
    pinnedSet,
    refreshPinnedSet,
    setPinnedSet: setPinnedSetWithLog,
    songs,
    viewSong,
  };
};
