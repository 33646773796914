import {
  Button,
  Container,
  Divider,
  Group,
  LoadingOverlay,
  SegmentedControl,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import {
  IconEdit,
  IconEye,
  IconFile,
  IconPin,
  IconPinnedOff,
} from '@tabler/icons';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import {
  CreateSetItemDto,
  SetCollectionDto,
  SetDto,
  SetItemDto,
  UpdateSetDto,
} from '../../api/openapi';
import { useAuthContext } from '../../context/auth-context';
import { useOpenSongsContext } from '../../context/open-songs.context';
import { AddSetItemForm } from '../molecules/AddSetItemForm';
import { FileDisplay } from '../molecules/FileDisplay';
import { TransferSetToModal } from '../molecules/TransferSetToModal';
import { AddSetItemModal } from '../organisms/AddSetItemModal';
import { useMediaQuery } from '@mantine/hooks';
import { SetItemPreview } from '../molecules/SetItemPreview';
import { UpdateSet } from '../organisms/UpdateSet';
import { ConfirmButton } from '../atoms/ConfirmButton';
import { SetItemLine } from '../molecules/SetItemLine';

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const SetPage = () => {
  const params = useParams();
  const auth = useAuthContext();
  const { pinnedSet, setPinnedSet } = useOpenSongsContext();
  const navigate = useNavigate();

  const mobile = useMediaQuery('(max-width: 1200px)');

  const [mode, setMode] = useState<'edit' | 'view' | 'file-preview'>(
    'file-preview',
  );
  const [set, setSet] = useState<SetDto>();
  const [sorted, setSorted] = useState<SetItemDto[]>([]);
  const [selected, setSelected] = useState<number>();
  const [disableKeys, setDisableKeys] = useState<boolean>(false);
  const [collections, setCollections] = useState<SetCollectionDto[]>([]);

  const allowEdit = auth.user?.id === set?.owner.id;

  const getSet = useCallback(async () => {
    if (params.setId) {
      const setId = parseInt(params.setId);
      const response = await SigmaApi.sets.setControllerGetSet(setId);
      setSet(response.data);
      if (pinnedSet?.id === response.data.id) {
        setPinnedSet(response.data);
      }
    }
  }, [params.setId, setSet, pinnedSet, setPinnedSet]);

  const getCollections = useCallback(async () => {
    const response = await SigmaApi.sets.setControllerGetSetCollections(false);
    setCollections(response.data);
  }, []);

  useEffect(() => {
    if (!set) {
      getSet();
    }
  }, [getSet, set]);

  useEffect(() => {
    getCollections();
  }, [getCollections]);

  useEffect(() => {
    if (set) {
      const sorted = set.items.sort((a, b) => a.index - b.index);
      setSorted(sorted);
    }
  }, [set]);

  useEffect(() => {
    if (sorted.length > 0 && selected === undefined) {
      setSelected(sorted[0].id);
    }
  }, [sorted, selected]);

  const scrollArea = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const elements = document.getElementsByClassName('selected-item');
    if (elements.length > 0 && scrollArea.current) {
      const body = scrollArea.current;
      const scrollAreaBox = body.getBoundingClientRect();
      const box = elements[0].getBoundingClientRect();

      if (
        !mobile &&
        (box.top < scrollAreaBox.top || box.bottom > scrollAreaBox.bottom)
      ) {
        const top = box.top + body.scrollTop - scrollAreaBox.top;

        scrollArea.current?.scrollTo({
          behavior: 'smooth',
          top: top,
        });
      }
      if (
        mobile &&
        (box.left < scrollAreaBox.left || box.right > scrollAreaBox.right)
      ) {
        const left = box.left + body.scrollLeft - scrollAreaBox.left;

        scrollArea.current?.scrollTo({
          behavior: 'smooth',
          left: left,
        });
      }
    }
  }, [selected, mobile]);

  const handleUpdateSetOrder = async (itemId: number, movement: 1 | -1) => {
    if (set) {
      // Reorder items in the list
      const itemIndex = sorted.findIndex((item) => item.id === itemId);
      let newIndex = itemIndex + movement;
      if (newIndex < 0) newIndex = 0;
      if (newIndex >= sorted.length) newIndex = sorted.length - 1;

      // Swap items
      const setItems = [...sorted];
      const movingItem = setItems[itemIndex];
      setItems[itemIndex] = setItems[newIndex];
      setItems[newIndex] = movingItem;

      setSorted([...setItems]);
      await SigmaApi.sets.setControllerReorderSetItems(set.id, {
        itemIds: setItems.map((item) => item.id),
      });
    }
  };

  const [setItemLoading, setSetItemLoading] = useState(false);
  const handleUpdateSetItem = async (id: number, dto: CreateSetItemDto) => {
    setSetItemLoading(true);
    await SigmaApi.sets.setControllerUpdateSetItem(id, dto);
    await getSet();
    setSetItemLoading(false);
  };

  const handleDeleteItem = async (id: number) => {
    setSetItemLoading(true);
    await SigmaApi.sets.setControllerDeleteSetItem(id);
    await getSet();
    setSetItemLoading(false);
  };

  // handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (!disableKeys) {
        if (event.key === 'ArrowUp') {
          event.preventDefault();
          setSelected((old) => {
            if (old === undefined) {
              return sorted[sorted.length - 1].id;
            }
            const currentIndex = sorted.findIndex((item) => item.id === old);
            let newIndex = currentIndex - 1;
            if (newIndex < 0) newIndex = 0;
            if (newIndex >= sorted.length) newIndex = sorted.length - 1;
            return sorted[newIndex].id;
          });
        } else if (event.key === 'ArrowDown') {
          event.preventDefault();
          setSelected((old) => {
            if (old === undefined) {
              return sorted[0].id;
            }
            const currentIndex = sorted.findIndex((item) => item.id === old);
            let newIndex = currentIndex + 1;
            if (newIndex < 0) newIndex = 0;
            if (newIndex >= sorted.length) newIndex = sorted.length - 1;
            return sorted[newIndex].id;
          });
        }
      }
    },
    [disableKeys, sorted],
  );

  useEffect(() => {
    // attach the event listener
    window.addEventListener('keydown', handleKeyPress);

    // remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  const addItemToSet = async (setItemDto: CreateSetItemDto) => {
    if (set) {
      await SigmaApi.sets.setControllerAddSetItem(set.id, setItemDto);
      await getSet();
    }
  };

  const handleUpdateSet = async (updates: UpdateSetDto) => {
    if (set) {
      await SigmaApi.sets.setControllerUpdateSet(set.id, updates);
      await getSet();
      await getCollections();
    }
  };

  const handleDeleteSet = async () => {
    if (set) {
      await SigmaApi.sets.setControllerDeleteSet(set.id);
    }
    navigate('/sets');
  };

  const togglePinStatus = () => {
    if (pinnedSet?.id === set?.id) {
      console.log('Removing pinned set');
      setPinnedSet(undefined);
    } else {
      console.log('Setting pinned set to current set');
      setPinnedSet(set);
    }
  };

  const selectedItem = sorted.find((item) => item.id === selected);
  return (
    <Stack>
      <LoadingOverlay visible={set ? false : true} />
      <Group justify="space-between">
        {set && (
          <Group>
            <Title order={1}>{set?.label}</Title>
            {pinnedSet?.id === set?.id && <IconPin />}
            <UpdateSet
              set={set}
              collections={collections}
              updateSet={handleUpdateSet}
              onOpen={() => setDisableKeys(true)}
              onClose={() => setDisableKeys(false)}
            />
          </Group>
        )}
        <Group>
          {(allowEdit || auth.user?.isAdmin) && set && (
            <TransferSetToModal set={set} refresh={getSet} />
          )}
          {allowEdit && (
            <Button
              leftSection={
                pinnedSet?.id === set?.id ? <IconPinnedOff /> : <IconPin />
              }
              onClick={() => togglePinStatus()}
              variant="outline"
            >
              {pinnedSet?.id === set?.id ? 'Unpin' : 'Pin'}
            </Button>
          )}
          {allowEdit && (
            <ConfirmButton
              label="Delete"
              message="Are you sure you want to delete this set?"
              onConfirm={handleDeleteSet}
              color="red"
              variant="outline"
            />
          )}
          <SegmentedControl
            data={[
              ...(allowEdit ? [{ label: <IconEdit />, value: 'edit' }] : []),
              { label: <IconEye />, value: 'view' },
              { label: <IconFile />, value: 'file-preview' },
            ]}
            value={mode}
            onChange={(value) => setMode(value as any)}
          />
        </Group>
      </Group>
      <Group>
        <Text c="dimmed">
          Created {dayjs(set?.createdAt).format('DD MMM YYYY')}
        </Text>
        <Text c="dimmed">Owner: {set?.owner.name}</Text>
      </Group>
      <Group gap="64px" align="start">
        <Stack
          gap="xs"
          p="md"
          style={{
            position: mobile ? undefined : 'sticky',
            top: mobile ? undefined : 60,
          }}
        >
          {sorted.map((item) => (
            <SetItemLine
              key={item.id}
              item={item}
              selected={item.id === selected}
              onClick={() => setSelected(item.id)}
              reorder={allowEdit}
              onDelete={() => handleDeleteItem(item.id)}
              onMoveUp={() => handleUpdateSetOrder(item.id, -1)}
              onMoveDown={() => handleUpdateSetOrder(item.id, 1)}
            />
          ))}
          {allowEdit && (
            <AddSetItemModal
              onSubmit={addItemToSet}
              onOpen={() => setDisableKeys(true)}
              onClose={() => setDisableKeys(false)}
            />
          )}
        </Stack>
        <Divider orientation="vertical" />
        {mode === 'file-preview' && (
          <div>
            {selectedItem && selectedItem.file && (
              <div style={{ maxWidth: '100%' }}>
                <FileDisplay
                  songName={selectedItem.song?.title ?? 'Song'}
                  songFile={selectedItem.file}
                />
              </div>
            )}
            {!selectedItem && (
              <Container>
                <Text>No preview available</Text>
              </Container>
            )}
            {selectedItem && !selectedItem.file && (
              <SetItemPreview setItem={selectedItem} />
            )}
          </div>
        )}
        {selectedItem && mode === 'view' && (
          <SetItemPreview setItem={selectedItem} />
        )}
        {mode === 'edit' && selectedItem && (
          <Stack>
            <Title order={4}>Editing selected item</Title>
            <LoadingOverlay visible={setItemLoading} />
            <AddSetItemForm
              key={selectedItem.id}
              mode="edit"
              onSubmit={(dto) => handleUpdateSetItem(selectedItem.id, dto)}
              enableDelete
              initial={selectedItem}
            />
          </Stack>
        )}
      </Group>
    </Stack>
  );
};
