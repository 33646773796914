import {
  Alert,
  Button,
  Checkbox,
  Group,
  Stack,
  TextInput,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { SigmaApi } from '../../api/api';
import { UserDto } from '../../api/openapi';
import { useAuthContext } from '../../context/auth-context';
import ErrorAlert from '../atoms/ErrorAlert';

type Props = {
  onSuccess?: () => void;
  userToEdit?: UserDto;
};
export default function EditUser({ onSuccess, userToEdit }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [username, setUsername] = useState<string>('');
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  useEffect(() => {
    setIsAdmin(userToEdit ? userToEdit.isAdmin : false);
    setName(userToEdit ? userToEdit.name : '');
    setUsername(userToEdit ? userToEdit.username : '');
    setEmail(userToEdit ? userToEdit.email : '');
    setSuccess(false);
    setError(false);
    setLoading(false);
  }, [userToEdit]);

  const auth = useAuthContext();

  if (
    !auth.user ||
    (!auth.user.isAdmin && userToEdit && userToEdit.id !== auth.user.id)
  ) {
    return <ErrorAlert>You don't have permission to edit this user</ErrorAlert>;
  }

  const updateUser: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (userToEdit) {
      setError(false);
      setLoading(true);
      try {
        if (auth.user?.isAdmin && auth.user.id !== userToEdit.id) {
          await SigmaApi.users.usersControllerUpdateUser(userToEdit.username, {
            email,
            isAdmin,
            name,
            username,
          });
        } else {
          await SigmaApi.users.usersControllerUpdateMe({
            email,
            name,
            username,
          });
        }
        setSuccess(true);
        if (onSuccess) onSuccess();
        await auth.refreshSession();
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }
  };

  return (
    <form onSubmit={updateUser}>
      <Stack>
        <Group grow>
          <TextInput
            label="Name"
            required
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextInput
            label="Email"
            type="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextInput
            label="Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Group>
        <Group justify="right">
          {success && <Alert style={{ flexGrow: 1 }}>User updated</Alert>}
          {error && (
            <ErrorAlert style={{ flexGrow: 1 }}>
              Failed to update user
            </ErrorAlert>
          )}
          {auth.user.isAdmin &&
            userToEdit &&
            auth.user.id !== userToEdit.id && (
              <Checkbox
                label="Is Admin?"
                labelPosition="left"
                checked={isAdmin}
                onChange={(e) => setIsAdmin(e.target.checked)}
              />
            )}
          <Button loading={loading} type="submit">
            Update user details
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
