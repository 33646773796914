import React from 'react';
import {
  SongDtoPreferredKeyEnum,
  SongFileDtoKeyEnum,
  SongKeyDtoKeyEnum,
} from '../../api/openapi';
import { IconKey } from '@tabler/icons';
import { KEYS_MAP } from '../../constants/keys';
import { Swatch } from './Swatch';

interface Props {
  songKey: SongDtoPreferredKeyEnum | SongKeyDtoKeyEnum | SongFileDtoKeyEnum;
}

export const KeySwatch = ({ songKey }: Props) => {
  const displayKey = KEYS_MAP[songKey];

  return (
    <Swatch
      tooltip="Key"
      label={displayKey.label}
      color={displayKey.color}
      icon={<IconKey />}
    />
  );
};
