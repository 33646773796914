import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../context/auth-context';

export const AdminRoute = ({ element }: { element: ReactNode }) => {
  const auth = useAuthContext();

  if (auth.user && auth.user.isAdmin) {
    return <>{element}</>;
  }
  return <Navigate to="/" />;
};
