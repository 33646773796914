import { ReactNode } from 'react';
import {
  Card,
  Group,
  Text,
  Tooltip,
  useComputedColorScheme,
} from '@mantine/core';

interface Props {
  tooltip: string;
  label: string;
  color: string;
  icon: ReactNode;
}

export const Swatch = ({ color, icon, label, tooltip }: Props) => {
  const colorScheme = useComputedColorScheme('light');

  return (
    <Tooltip label={tooltip}>
      <Card
        pl={8}
        pr={10}
        py={2}
        radius="xl"
        withBorder={true}
        style={(theme) => {
          return {
            background:
              colorScheme === 'dark'
                ? theme.colors[color][9]
                : theme.colors[color][0],
            borderColor: color,
            borderWidth: 2,
          };
        }}
      >
        <Group gap={3} align="initial" wrap="nowrap">
          {icon}
          <Text>{label}</Text>
        </Group>
      </Card>
    </Tooltip>
  );
};
