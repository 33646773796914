import {
  ActionIcon,
  Button,
  Modal,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { SetCollectionDto, SetDto, UpdateSetDto } from '../../api/openapi';
import { IconEdit } from '@tabler/icons';
import { FormEventHandler, useEffect, useState } from 'react';
import { CreatableMultiSelect } from '../atoms/CreatableMultiSelect';

export const UpdateSet = ({
  collections,
  onClose,
  onOpen,
  set,
  updateSet,
}: {
  set: SetDto;
  collections: SetCollectionDto[];
  updateSet: (updates: UpdateSetDto) => Promise<void>;
  onOpen?: () => void;
  onClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [label, setLabel] = useState(set.label);
  const [selectedCollections, setSelectedCollections] = useState(
    set.collections?.map((c) => ({
      label: c.label,
      value: c.id.toString(),
    })) ?? [],
  );

  const [collectionData, setCollectionData] = useState(
    collections.map((collection) => ({
      label: collection.label,
      value: collection.id.toString(),
    })),
  );

  useEffect(() => {
    if (collections) {
      setCollectionData(
        collections.map((collection) => ({
          label: collection.label,
          value: collection.id.toString(),
        })),
      );
    }
  }, [collections]);

  useEffect(() => {
    if (set.collections) {
      setSelectedCollections(
        set.collections.map((c) => ({
          label: c.label,
          value: c.id.toString(),
        })),
      );
    }
  }, [set.collections]);

  const handleUpdate: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setLoading(true);

    // Get existing collections
    const newCollectionNames = [];
    const existingCollectionIds = [];
    for (const collection of selectedCollections) {
      const id = parseInt(collection.value);
      if (isNaN(id)) {
        newCollectionNames.push(collection.value);
      } else {
        existingCollectionIds.push(id);
      }
    }

    await updateSet({
      collectionIds: existingCollectionIds,
      label,
      newCollectionNames,
    });
    setLoading(false);
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <>
      <ActionIcon variant="subtle" onClick={() => handleOpen()}>
        <IconEdit />
      </ActionIcon>
      <Modal opened={open} onClose={() => handleClose()}>
        <form onSubmit={handleUpdate}>
          <Stack mih="40vh" justify="space-between">
            <Stack>
              <Title order={2}>Update {set.label}</Title>
              <TextInput
                value={label}
                label="Label"
                onChange={(event) => setLabel(event.target.value)}
              />
              <CreatableMultiSelect
                label="Collections"
                description="Collections this set is a part of"
                data={collectionData}
                onCreate={(query) => {
                  const item = { label: query, value: query };
                  setCollectionData((current) => [...current, item]);
                  return item;
                }}
                value={selectedCollections}
                onChange={setSelectedCollections}
              />
            </Stack>

            <Button type="submit" loading={loading}>
              Save
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
