import {
  ActionIcon,
  Button,
  Modal,
  MultiSelect,
  Stack,
  TextInput,
  Title,
} from '@mantine/core';
import { IconEdit } from '@tabler/icons';
import {
  SetCollectionDto,
  SetDto,
  UpdateSetCollectionDto,
} from '../../api/openapi';
import { useState, useEffect, FormEventHandler } from 'react';

export const UpdateCollection = ({
  collection,
  onClose,
  onOpen,
  sets,
  updateCollection,
}: {
  collection: SetCollectionDto;
  sets: SetDto[];
  updateCollection: (updates: UpdateSetCollectionDto) => Promise<void>;
  onOpen?: () => void;
  onClose?: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [label, setLabel] = useState(collection.label);
  const [selectedSets, setSelectedSets] = useState(
    collection.sets?.map((c) => c.id.toString()) ?? [],
  );

  const [setData, setSetData] = useState(
    sets.map((set) => ({
      label: set.label,
      value: set.id.toString(),
    })),
  );

  useEffect(() => {
    if (sets) {
      setSetData(
        sets.map((set) => ({
          label: set.label,
          value: set.id.toString(),
        })),
      );
    }
  }, [sets]);

  useEffect(() => {
    if (collection.sets) {
      setSelectedSets(collection.sets.map((s) => s.id.toString()));
    }
  }, [collection.sets]);

  const handleUpdate: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();

    setLoading(true);
    await updateCollection({
      label,
      setIds: selectedSets.map((id) => parseInt(id)),
    });
    setLoading(false);
    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };
  return (
    <>
      <ActionIcon variant="subtle" onClick={handleOpen}>
        <IconEdit />
      </ActionIcon>
      <Modal opened={open} onClose={handleClose}>
        <form onSubmit={handleUpdate}>
          <Stack mih="40vh" justify="space-between">
            <Stack>
              <Title order={2}>Update {collection.label}</Title>
              <TextInput
                value={label}
                label="Label"
                onChange={(event) => setLabel(event.target.value)}
              />
              <MultiSelect
                label="Sets"
                description="Sets in this collection"
                searchable
                data={setData}
                value={selectedSets}
                onChange={setSelectedSets}
              />
            </Stack>
            <Button type="submit" loading={loading}>
              Save
            </Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
