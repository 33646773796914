import {
  AppShell,
  Text,
  Burger,
  Button,
  Divider,
  Container,
  Group,
  Stack,
  Box,
  ActionIcon,
  useMantineColorScheme,
  Space,
} from '@mantine/core';
import { useCallback, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context/auth-context';
import { USER_ROUTES, PRIVATE_ROUTES } from '../../routes';
import { NavLinks } from './NavLinks';
import { OpenSongLinks } from './OpenSongLinks';
import { PinnedSetLink } from './PinnedSetLink';
import { IconArrowsShuffle2, IconSunMoon } from '@tabler/icons';
import { SigmaApi } from '../../api/api';

export default function PrivateRoute() {
  const [open, setOpen] = useState(false);
  const auth = useAuthContext();
  const location = useLocation();
  const navigate = useNavigate();

  const { toggleColorScheme } = useMantineColorScheme();

  const goToRandomSong = useCallback(
    async (include: string[] = [], exclude: string[] = []) => {
      const response = await SigmaApi.songs.songsControllerGetRandomSongs(
        1,
        include,
        exclude,
      );

      const songId = response.data[0].id;
      navigate(`/songs/${songId}`);
    },
    [navigate],
  );

  if (!auth.user) {
    const params = new URLSearchParams();
    params.append('returnTo', location.pathname);
    return <Navigate to={`/login?${params.toString()}`} />;
  }

  return (
    <AppShell
      padding="xs"
      navbar={{
        breakpoint: 'md',
        collapsed: { mobile: !open },
        width: { lg: 300, sm: 200 },
      }}
      header={{ height: 60 }}
    >
      <AppShell.Header p="sm">
        <Group gap="lg">
          <Burger
            opened={open}
            onClick={() => setOpen((o) => !o)}
            hiddenFrom="md"
            size="sm"
            style={{ position: 'absolute' }}
          />
          <Text hiddenFrom="md" style={{ flex: 1, textAlign: 'center' }}>
            Sigma
          </Text>
          <Text visibleFrom="md">Sigma</Text>
          <Button
            leftSection={<IconArrowsShuffle2 />}
            variant="gradient"
            gradient={{ deg: 90, from: 'pink', to: 'gray' }}
            onClick={() => goToRandomSong()}
          >
            SHUFFLE
          </Button>
          <Space style={{ flex: 1 }} visibleFrom="md" />
          <ActionIcon
            variant="outline"
            color="orange"
            onClick={() => toggleColorScheme()}
          >
            <IconSunMoon />
          </ActionIcon>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar>
        <Stack p={8} gap={0} style={{ height: '100%' }}>
          <Box style={{ flex: 1 }}>
            <NavLinks
              routes={PRIVATE_ROUTES}
              isAdmin={auth.user.isAdmin}
              callback={() => setOpen(false)}
            />
          </Box>
          <PinnedSetLink callback={() => setOpen(false)} />
          <OpenSongLinks callback={() => setOpen(false)} />
          <Divider />
          <NavLinks
            routes={USER_ROUTES}
            isAdmin={auth.user.isAdmin}
            callback={() => setOpen(false)}
          />
          <Divider />
          <Button fullWidth variant="subtle" onClick={() => auth.signOut()}>
            Logout
          </Button>
        </Stack>
      </AppShell.Navbar>

      <AppShell.Main style={{ height: '100%', position: 'relative' }}>
        <Container size={1550} style={{ padding: 0 }}>
          <Outlet />
        </Container>
      </AppShell.Main>
    </AppShell>
  );
}
