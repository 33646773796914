import { Select } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { SongFileDtoMusicTypeEnum } from '../../api/openapi';
import { MUSIC_TYPES, MUSIC_TYPE_COLOURS } from '../../constants/music-type';

type Props = {
  label?: string;
  value?: SongFileDtoMusicTypeEnum;
  setValue: any;
};

export const MusicTypeSelector = ({ label = '', setValue, value }: Props) => {
  const viewport = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      const index = MUSIC_TYPES.findIndex((type) => type.value === value);
      viewport.current?.scrollTo({
        behavior: 'smooth',
        left:
          (viewport.current.scrollWidth / MUSIC_TYPES.length) * index -
          viewport.current.clientWidth / 2 +
          10,
      });
    }
  }, [value]);

  return (
    <Select
      label={label}
      value={value}
      onChange={setValue}
      data={MUSIC_TYPES}
      color={value ? MUSIC_TYPE_COLOURS[value] : undefined}
    />
  );
};
