import { Anchor, Breadcrumbs, Group, Stack, Title } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  SetCollectionDto,
  SetDto,
  UpdateSetCollectionDto,
} from '../../api/openapi';
import { SigmaApi } from '../../api/api';
import { SetList } from '../organisms/SetList';
import { UpdateCollection } from '../organisms/UpdateCollection';

export const CollectionPage = () => {
  const params = useParams();

  const [collection, setCollection] = useState<SetCollectionDto>();
  const [sets, setSets] = useState<SetDto[]>([]);

  const getCollection = useCallback(async (collectionId: number) => {
    const response =
      await SigmaApi.sets.setControllerGetSetCollection(collectionId);
    setCollection(response.data);
  }, []);

  const getSets = useCallback(async () => {
    const response = await SigmaApi.sets.setControllerListSets(false);
    setSets(response.data);
  }, []);
  useEffect(() => {
    const collectionId = parseInt(params.collectionId ?? '');
    if (!isNaN(collectionId)) {
      getCollection(collectionId);
    }
  }, [params.collectionId, getCollection]);

  useEffect(() => {
    getSets();
  }, [getSets]);

  const handleUpdateCollection = async (updates: UpdateSetCollectionDto) => {
    if (collection) {
      await SigmaApi.sets.setControllerUpdateSetCollection(
        collection.id,
        updates,
      );
      await getCollection(collection.id);
      await getSets();
    }
  };
  return (
    <Stack>
      <Breadcrumbs>
        <Anchor component={Link} to={'/sets'}>
          Sets
        </Anchor>
        <Anchor component={Link} to={'#'}>
          {collection?.label} (Collection)
        </Anchor>
      </Breadcrumbs>
      <Group justify="space-between" wrap="nowrap">
        <Title>{collection?.label}</Title>
        {collection && (
          <UpdateCollection
            collection={collection}
            updateCollection={handleUpdateCollection}
            sets={sets}
          />
        )}
      </Group>
      {collection?.sets && (
        <SetList sets={collection.sets} setsPerPage={collection.sets.length} />
      )}
    </Stack>
  );
};
