import {
  Group,
  HoverCard,
  Text,
  Stack,
  StackProps,
  Table,
  ActionIcon,
  Badge,
  ScrollArea,
  Tooltip,
  LoadingOverlay,
  ThemeIcon,
  Space,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import {
  IconFriends,
  IconGuitarPick,
  IconHeadphones,
  IconInfoCircle,
  IconMusic,
  IconPin,
} from '@tabler/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import { SongDto, SongFileDtoMusicTypeEnum } from '../../api/openapi';
import { KEYS_MAP } from '../../constants/keys';
import { useOpenSongsContext } from '../../context/open-songs.context';
import { SongTitle } from '../molecules/SongTitle';

export const SongList = ({
  songs,
  ...forwarded
}: {
  songs: SongDto[];
} & StackProps) => {
  const navigate = useNavigate();
  const { pinnedSet, refreshPinnedSet } = useOpenSongsContext();

  const [actionLoading, setActionLoading] = useState(-1);

  const handleAddSongToSet = async (song: SongDto) => {
    if (pinnedSet && song.files.length > 0) {
      setActionLoading(song.id);
      await SigmaApi.sets.setControllerAddSetItem(pinnedSet.id, {
        chosenKey: song.preferredKey,
        file: song.files[0].id,
        song: song.id,
        type: 'song',
      });
      await refreshPinnedSet();
      showNotification({
        message: `${song.title} was added to ${pinnedSet.label}`,
        title: 'Song added to pinned set',
      });
      setActionLoading(-1);
    }
  };

  return (
    <Stack {...forwarded} gap={0}>
      <Table highlightOnHover style={{ ':hover': { cursor: 'pointer' } }}>
        <Table.Thead>
          <Table.Tr></Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          {songs.map((song) => {
            let hasChordFile = false;
            let hasLeadFile = false;
            let hasChoralFile = false;
            song.files.forEach((file) => {
              switch (file.musicType) {
                case SongFileDtoMusicTypeEnum.Choral:
                  hasChoralFile = true;
                  break;
                case SongFileDtoMusicTypeEnum.Chord:
                  hasChordFile = true;
                  break;
                case SongFileDtoMusicTypeEnum.Lead:
                  hasLeadFile = true;
                  break;
              }
            });

            return (
              <Table.Tr
                key={song.id}
                onClick={() => navigate(`/songs/${song.id}`)}
              >
                <LoadingOverlay visible={actionLoading === song.id} />
                <Table.Td>
                  <Group>
                    <Group gap={2}>
                      {song.youtubeVideoId ? (
                        <Tooltip label="has Youtube link">
                          <ThemeIcon
                            variant="outline"
                            radius="xl"
                            c="cyan"
                            size="xs"
                            style={{ border: 'none' }}
                          >
                            <IconHeadphones />
                          </ThemeIcon>
                        </Tooltip>
                      ) : (
                        <Space w={18} visibleFrom="md" />
                      )}
                      {hasChordFile ? (
                        <Tooltip label="has chordsheet">
                          <ThemeIcon
                            variant="outline"
                            radius="xl"
                            c="cyan"
                            size="xs"
                            style={{ border: 'none' }}
                          >
                            <IconGuitarPick />
                          </ThemeIcon>
                        </Tooltip>
                      ) : (
                        <Space w={18} visibleFrom="md" />
                      )}
                      {hasLeadFile ? (
                        <Tooltip label="has leadsheet">
                          <ThemeIcon
                            variant="outline"
                            radius="xl"
                            c="cyan"
                            size="xs"
                            style={{ border: 'none' }}
                          >
                            <IconMusic />
                          </ThemeIcon>
                        </Tooltip>
                      ) : (
                        <Space w={18} visibleFrom="md" />
                      )}
                      {hasChoralFile ? (
                        <Tooltip label="has choral sheet">
                          <ThemeIcon
                            variant="outline"
                            radius="xl"
                            c="cyan"
                            size="xs"
                            style={{ border: 'none' }}
                          >
                            <IconFriends />
                          </ThemeIcon>
                        </Tooltip>
                      ) : (
                        <Space w={18} visibleFrom="md" />
                      )}
                    </Group>
                    <Group gap={4}>
                      {KEYS_MAP[song.preferredKey].label}
                      {song.keys.length > 0 ? (
                        <HoverCard withArrow offset={-5}>
                          <HoverCard.Target>
                            <ActionIcon variant="subtle">
                              <IconInfoCircle color="gray" size={15} />
                            </ActionIcon>
                          </HoverCard.Target>
                          <HoverCard.Dropdown>
                            <Text size="xs" fw="bold">
                              Other keys
                            </Text>
                            {song.keys.map((key) => (
                              <Text key={key.id} size="xs" fs="italic">
                                {key.name} - {KEYS_MAP[song.preferredKey].label}
                              </Text>
                            ))}
                          </HoverCard.Dropdown>
                        </HoverCard>
                      ) : null}
                    </Group>
                    <SongTitle title={song.title} altTitles={song.altTitles} />
                  </Group>
                </Table.Td>
                <Table.Td visibleFrom="md">
                  <ScrollArea
                    style={{ height: 25, width: '100%' }}
                    type="never"
                  >
                    <Group wrap="nowrap">
                      {song.tags.map((tag) => (
                        <Badge key={tag.id} color={tag.color}>
                          {tag.name}
                        </Badge>
                      ))}
                    </Group>
                  </ScrollArea>
                </Table.Td>
                <Table.Td>
                  {pinnedSet && song.files.length > 0 && (
                    <Tooltip label="Add to pinned set">
                      <ActionIcon
                        variant="subtle"
                        onClick={(event: React.MouseEvent) => {
                          event.stopPropagation();
                          handleAddSongToSet(song);
                        }}
                        size="xs"
                      >
                        <IconPin />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </Stack>
  );
};
