import { Card, Group, Stack, Title, Text, Space, Anchor } from '@mantine/core';
import { SetCollectionDto } from '../../api/openapi';
import { IconBucket } from '@tabler/icons';
import { Link } from 'react-router-dom';

export const SetCollectionCard = ({
  collection,
}: {
  collection: SetCollectionDto;
}) => {
  return (
    <Card shadow="sm" withBorder padding="lg" miw={300}>
      <Group justify="space-between" wrap="nowrap">
        <Anchor
          component={Link}
          to={`/collections/${collection.id}`}
          lineClamp={1}
          fz="lg"
          fw={700}
          maw={210}
        >
          {collection.label}
        </Anchor>
        <IconBucket />
      </Group>
      <Space h={8} />
      <Group justify="space-between" align="top" wrap="nowrap">
        {collection.sets && (
          <Stack gap={0}>
            {collection.sets.slice(0, 3).map((set) => (
              <Text lineClamp={1}>{set.label}</Text>
            ))}
          </Stack>
        )}
        <Stack gap={0} align="center">
          <Title>{collection.setsCount}</Title>
          <Title order={3}>Sets</Title>
        </Stack>
      </Group>
    </Card>
  );
};
