import {
  Title,
  Stack,
  Table,
  LoadingOverlay,
  Container,
  Group,
  ActionIcon,
  useMantineTheme,
  Overlay,
  Tooltip,
  Button,
  Checkbox,
  Divider,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconRefresh, IconX } from '@tabler/icons';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import { UserDto } from '../../api/openapi';
import { useAuthContext } from '../../context/auth-context';
import EditUser from '../organisms/EditUser';
import { MergeSongsForm } from '../organisms/MergeSongsForm';
import ResetPassword from '../organisms/ResetPassword';
import UserCreation from '../organisms/UserCreation';

export default function AdminPage() {
  const auth = useAuthContext();
  const [users, setUsers] = useState<UserDto[]>();
  const [selectedUser, setSelectedUser] = useState<UserDto>();
  const [allowDelete, setAllowDelete] = useState<boolean>(false);

  const [enableDangerZone, setEnableDangerZone] = useState<boolean>(false);
  const [dangerZoneLoading, setDangerZoneLoading] = useState<boolean>(false);
  const theme = useMantineTheme();

  const getUsers = useCallback(async () => {
    const response = await SigmaApi.users.usersControllerGetUsers();
    setUsers(response.data);
  }, []);

  async function deleteSelectedUser() {
    if (selectedUser) {
      await SigmaApi.users.usersControllerDeleteUser(selectedUser.username);
      await getUsers();
      changeSelectedUser();
    }
  }

  function changeSelectedUser(user?: UserDto) {
    setSelectedUser(user);
    setAllowDelete(false);
  }

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  if (auth.user && !auth.user.isAdmin) {
    return <Navigate to="/" />;
  }

  return (
    <Container style={{ position: 'relative' }} size="md">
      <Stack>
        <LoadingOverlay visible={!users} />
        <Group>
          <Title order={4}>Manage Users</Title>
          <ActionIcon variant="subtle" onClick={getUsers}>
            <IconRefresh />
          </ActionIcon>
        </Group>
        <Table withTableBorder highlightOnHover>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>Name</Table.Th>
              <Table.Th>Username</Table.Th>
              <Table.Th>Email</Table.Th>
              <Table.Th>Is Admin?</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {users &&
              users.map((user) => (
                <Table.Tr
                  key={user.id}
                  onClick={() =>
                    selectedUser && user.id === selectedUser.id
                      ? changeSelectedUser()
                      : changeSelectedUser(user)
                  }
                  style={{
                    background:
                      selectedUser && user.id === selectedUser.id
                        ? theme.colors[theme.primaryColor][1]
                        : undefined,
                  }}
                >
                  <Table.Td>{user.name}</Table.Td>
                  <Table.Td>{user.username}</Table.Td>
                  <Table.Td>{user.email}</Table.Td>
                  <Table.Td>
                    {user.isAdmin ? <IconCheck /> : <IconX />}
                  </Table.Td>
                </Table.Tr>
              ))}
          </Table.Tbody>
        </Table>
        <Stack style={{ borderRadius: 5, position: 'relative' }} p="md">
          <Title order={6}>Add a new user</Title>
          <UserCreation onSuccess={getUsers} />
        </Stack>
        <Tooltip
          label="Select a user to edit them"
          offset={-100}
          opened={!selectedUser}
        >
          <Stack style={{ borderRadius: 5, position: 'relative' }} p="md">
            {!selectedUser && <Overlay color="#000" blur={2} />}
            <Title order={6}>Edit selected user</Title>
            <EditUser userToEdit={selectedUser} onSuccess={getUsers} />
            <ResetPassword userToReset={selectedUser} />
            <Group>
              <Checkbox
                color="red"
                checked={allowDelete}
                onChange={(e) => setAllowDelete(e.target.checked)}
                label="Enable Delete?"
                disabled={selectedUser?.id === auth.user?.id}
              />
              <Button
                color="red"
                onClick={deleteSelectedUser}
                disabled={!allowDelete || selectedUser?.id === auth.user?.id}
                style={{ flexGrow: 1 }}
              >
                Delete User {selectedUser?.username}
              </Button>
            </Group>
          </Stack>
        </Tooltip>
        <Divider />
        <Group align="baseline">
          <Title>DANGER ZONE</Title>
          <Checkbox
            label="Enable?"
            checked={enableDangerZone}
            onChange={(e) => setEnableDangerZone(e.target.checked)}
          />
        </Group>
        <Group grow>
          <Button
            color="orange"
            loading={dangerZoneLoading}
            disabled={!enableDangerZone}
            onClick={async () => {
              setDangerZoneLoading(true);
              try {
                const songs =
                  await SigmaApi.admin.adminControllerInitializeDatabase();

                showNotification({
                  message: `${songs.data.length} songs in library.`,
                  title: 'Initialization succeeded',
                });
              } catch (err) {
                let error = 'Unknown';
                if (err instanceof AxiosError) {
                  error = err.response?.data;
                }
                showNotification({
                  message: `ERROR: ${JSON.stringify(error)}`,
                  title: 'Initialization FAILED',
                });
              }
              setDangerZoneLoading(false);
            }}
          >
            Refresh database from filesystem
          </Button>
          <Button
            color="violet"
            disabled={!enableDangerZone}
            loading={dangerZoneLoading}
            onClick={async () => {
              setDangerZoneLoading(true);
              try {
                const songs =
                  await SigmaApi.admin.adminControllerPersistDatabase();

                showNotification({
                  message: `${songs.data.length} songs in library.`,
                  title: 'Persist succeeded',
                });
              } catch (err) {
                let error = 'Unknown';
                if (err instanceof AxiosError) {
                  error = err.response?.data;
                }
                showNotification({
                  message: `ERROR: ${JSON.stringify(error)}`,
                  title: 'Persist FAILED',
                });
              }
              setDangerZoneLoading(false);
            }}
          >
            Save database to filesystem
          </Button>
        </Group>
        <MergeSongsForm disabled={!enableDangerZone} />
      </Stack>
    </Container>
  );
}
