import { Stack, Title, Text, Group, Button } from '@mantine/core';
import { SetItemDto } from '../../api/openapi';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { YoutubeEmbed } from '../atoms/YoutubeEmbed';
import { Link } from 'react-router-dom';
import { SetItemType } from '../atoms/SetItemType';
import { KEYS_MAP } from '../../constants/keys';
import { KeySwatch } from '../atoms/KeySwatch';
import { MusicTypeSwatch } from '../atoms/MusicTypeSwatch';

dayjs.extend(duration);
dayjs.extend(relativeTime);
type Props = {
  setItem: SetItemDto;
};

export const SetItemPreview = ({ setItem }: Props) => {
  const estimatedTimeString = setItem.estimatedTimeSeconds
    ? dayjs.duration(setItem.estimatedTimeSeconds, 'seconds').humanize()
    : 'Not specified';
  if (setItem.type === 'song' && setItem.song) {
    return (
      <Stack>
        <Group>
          <SetItemType type={setItem.type} />
          <Title>{setItem.song.title}</Title>
          <Button
            component={Link}
            to={`/songs/${setItem.song.id}`}
            variant="subtle"
          >
            Go to song page
          </Button>
        </Group>
        <Group>
          <Text color="dimmed">Estimated time: {estimatedTimeString}</Text>
          {setItem.chosenKey && (
            <Text color="dimmed">Key: {KEYS_MAP[setItem.chosenKey].label}</Text>
          )}
        </Group>
        <Text color="dimmed">{setItem.order}</Text>
        <Text>Notes: {setItem.notes}</Text>
        {setItem.file && (
          <Group>
            <Text>Chosen file: {setItem.file.name}</Text>
            <KeySwatch songKey={setItem.file.key} />
            <MusicTypeSwatch songType={setItem.file.musicType} />
          </Group>
        )}
        {setItem.song.youtubeVideoId && (
          <YoutubeEmbed
            songName={setItem.song.title}
            embedId={setItem.song.youtubeVideoId}
            style={{ height: 300, width: 500 }}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack>
      <Text>{`Estimated time: ${estimatedTimeString}`}</Text>
      <Text>{setItem.notes}</Text>
    </Stack>
  );
};
