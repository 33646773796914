import { Title, Stack, Container } from '@mantine/core';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../../context/auth-context';
import EditUser from '../organisms/EditUser';
import UpdatePassword from '../organisms/UpdatePassword';

export default function AccountPage() {
  const auth = useAuthContext();

  if (!auth.user) {
    return <Navigate to="/login" />;
  }

  return (
    <Container style={{ position: 'relative' }} size="md">
      <Stack>
        <Title order={4}>Your Account</Title>
        <EditUser userToEdit={auth.user} />
        <UpdatePassword />
      </Stack>
    </Container>
  );
}
