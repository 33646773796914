import { IconMusic, IconSquareDot } from '@tabler/icons';
import { SetItemDto } from '../../api/openapi';

type Props = {
  type: SetItemDto['type'];
};

export const SetItemType = ({ type }: Props) => {
  switch (type) {
    case 'song':
      return <IconMusic />;
    case 'SetItem':
      return <IconSquareDot />;
    default:
      return <></>;
  }
};
