import { Group, Text } from '@mantine/core';
import { IconFile, IconFileText, IconPhoto } from '@tabler/icons';

export const FileType = ({ type }: { type: string }) => {
  let text = '?';
  let Icon = IconFile;
  if (type.includes('pdf')) {
    text = 'PDF';
    Icon = IconFileText;
  } else if (type.includes('image')) {
    text = type.split('/')[1].toUpperCase();
    Icon = IconPhoto;
  }

  return (
    <Group gap={1}>
      <Icon />
      <Text size="xs">{text}</Text>
    </Group>
  );
};
