import {
  Button,
  Group,
  NumberInput,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useCallback, useEffect, useState } from 'react';
import { SigmaApi } from '../../api/api';
import {
  CreateSetItemDto,
  CreateSetItemDtoChosenKeyEnum,
  SetItemDto,
  SongDto,
} from '../../api/openapi';
import { KEYS } from '../../constants/keys';
dayjs.extend(duration);
dayjs.extend(relativeTime);

type Props = {
  onSubmit: (createSetItemDto: CreateSetItemDto) => void | Promise<void>;
  mode?: 'edit' | 'create';
  initial?: SetItemDto;
  enableDelete?: boolean;
  onDelete?: () => void | Promise<void>;
};

export const AddSetItemForm = ({
  enableDelete,
  initial,
  mode = 'create',
  onDelete,
  onSubmit,
}: Props) => {
  const [songs, setSongs] = useState<SongDto[]>([]);
  const [selectedSong, setSelectedSong] = useState<SongDto>();

  const getSongs = useCallback(async () => {
    const response = await SigmaApi.songs.songsControllerListSongs();
    setSongs(response.data);
  }, []);

  useEffect(() => {
    getSongs();
  }, [getSongs]);

  const form = useForm({
    initialValues: {
      chosenKey: initial?.chosenKey ? initial.chosenKey : '',
      estimatedTimeSeconds: initial?.estimatedTimeSeconds
        ? initial.estimatedTimeSeconds
        : undefined,
      notes: initial?.notes ? initial.notes : '',
      order: initial?.order ? initial.order : '',
      song: initial?.song ? initial.song.id.toString() : '',
      songFile: initial?.file ? initial.file.id.toString() : '',
      type: initial ? initial.type : 'song',
    },
  });

  useEffect(() => {
    if (form.values.song.length > 0) {
      const song = songs.find((song) => song.id === parseInt(form.values.song));
      if (song) {
        setSelectedSong(song);
      }
    }
  }, [form.values.song, songs]);

  return (
    <form
      onSubmit={form.onSubmit((values) =>
        onSubmit({
          chosenKey: values.chosenKey as CreateSetItemDtoChosenKeyEnum,
          estimatedTimeSeconds: values.estimatedTimeSeconds,
          file: parseInt(values.songFile),
          notes: values.notes,
          order: values.order,
          song: parseInt(values.song),
          type: values.type,
        }),
      )}
    >
      <Stack>
        <Group>
          <Select
            style={{ flex: 1 }}
            label="Item type"
            data={[
              { label: 'Song', value: 'song' },
              { label: 'General', value: 'SetItem' },
            ]}
            required
            {...form.getInputProps('type')}
          />
        </Group>
        {form.values.type === 'song' && (
          <>
            <Group grow>
              <Select
                required
                searchable
                label="Song"
                data={songs.map((song) => ({
                  label: song.title,
                  value: song.id.toString(),
                }))}
                {...form.getInputProps('song')}
              />
              <Select
                required
                label="Key"
                data={KEYS}
                {...form.getInputProps('chosenKey')}
              />
            </Group>
            {selectedSong && (
              <Select
                required
                label="File"
                data={selectedSong.files.map((file) => ({
                  label: `${file.name} (Key: ${file.key})`,
                  value: file.id.toString(),
                }))}
                {...form.getInputProps('songFile')}
              />
            )}
            <TextInput label="Song order" {...form.getInputProps('order')} />
          </>
        )}
        <Textarea label="Notes" {...form.getInputProps('notes')} />
        <NumberInput
          label="Estimated Time (seconds)"
          rightSection={
            <Text size="xs" color="dimmed">
              {form.values.estimatedTimeSeconds
                ? dayjs
                    .duration(form.values.estimatedTimeSeconds, 'seconds')
                    .humanize()
                : ''}
            </Text>
          }
          rightSectionWidth={90}
          {...form.getInputProps('estimatedTimeSeconds')}
        />
        <Group grow>
          {enableDelete && onDelete && (
            <Button color="red" onClick={() => onDelete()}>
              Delete
            </Button>
          )}
          <Button type="submit">{mode === 'edit' ? 'Update' : 'Submit'}</Button>
        </Group>
      </Stack>
    </form>
  );
};
