import { Group, ScrollArea } from '@mantine/core';
import { SetCollectionDto } from '../../api/openapi';
import { SetCollectionCard } from '../molecules/SetCollectionCard';

export const SetCollections = ({
  collections,
}: {
  collections: SetCollectionDto[];
}) => {
  return (
    <ScrollArea offsetScrollbars>
      <Group wrap="nowrap">
        {collections.map((collection) => (
          <SetCollectionCard collection={collection} />
        ))}
      </Group>
    </ScrollArea>
  );
};
