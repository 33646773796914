import React, { createContext, useContext } from 'react';
import { SetDto, SongDto } from '../api/openapi';
import { useOpenSongs } from '../hooks/use-open-songs';

export const OpenSongsContext = createContext<ReturnType<typeof useOpenSongs>>({
  openSong: (song: SongDto) => {
    return;
  },
  pinnedSet: undefined,
  refreshPinnedSet: async () => {
    return;
  },
  setPinnedSet: (set: React.SetStateAction<SetDto | undefined>) => {
    return;
  },
  songs: [],
  viewSong: (id) => {
    return;
  },
});
export const useOpenSongsContext = () => useContext(OpenSongsContext);

type Props = {
  children: React.ReactNode;
};

const OpenSongsProvider = ({ children }: Props) => {
  const open = useOpenSongs();
  return (
    <OpenSongsContext.Provider value={open}>
      {children}
    </OpenSongsContext.Provider>
  );
};

export default OpenSongsProvider;
