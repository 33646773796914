import { Button, Stack, Modal, LoadingOverlay } from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { useState } from 'react';
import { SigmaApi } from '../../api/api';
import { SongDto } from '../../api/openapi';
import ErrorAlert from '../atoms/ErrorAlert';
import { FileDropzone, SongFile } from '../molecules/FileDropzone';
import { IconUpload } from '@tabler/icons';

type Props = {
  song: SongDto;
  onOpen?: () => void;
  onClose?: () => void;
  onSuccess?: (updatedSong: SongDto) => void;
};

export const UploadSongFile = ({ onClose, onOpen, onSuccess, song }: Props) => {
  const [opened, setOpened] = useState<boolean>(false);
  const [value, setValue] = useState<SongFile[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onSave = async () => {
    setLoading(true);
    setError(false);
    try {
      const response = await SigmaApi.songs.songsControllerUploadFilesToSong(
        song.id,
        value.map((v) => v.file),
        JSON.stringify(value),
      );
      const updatedSong = response.data;
      // Success!
      handleClose();
      showNotification({
        message: `${value.map((v) => v.name).join(', ')} uploaded successfully`,
        title: `Files uploaded successfully to ${updatedSong.title}`,
      });
      setValue([]);
      if (onSuccess) onSuccess(updatedSong);
    } catch (err) {
      setError(true);
    }
    setLoading(false);
  };

  const handleClose = () => {
    setOpened(false);
    if (onClose) onClose();
  };
  const handleOpen = () => {
    setOpened(true);
    if (onOpen) onOpen();
  };
  return (
    <>
      <Modal
        title={`Add files to ${song.title}`}
        opened={opened}
        onClose={handleClose}
        size={800}
      >
        <LoadingOverlay visible={loading} />
        <Stack>
          <FileDropzone value={value} onChange={setValue} />
          <Button onClick={onSave}>Upload</Button>
          {error && (
            <ErrorAlert>There was a problem uploading your files</ErrorAlert>
          )}
        </Stack>
      </Modal>

      <Button
        onClick={handleOpen}
        variant="outline"
        leftSection={<IconUpload />}
      >
        Upload New File
      </Button>
    </>
  );
};
