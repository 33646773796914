import { Select } from '@mantine/core';
import { SongFileDto } from '../../api/openapi';
import { useMemo } from 'react';

export const SongFileList = ({
  onSelectFile,
  selectedFile,
  songFiles,
}: {
  selectedFile: SongFileDto;
  songFiles: SongFileDto[];
  onSelectFile: (file: SongFileDto) => void;
}) => {
  const filesById = useMemo(
    () =>
      songFiles.reduce(
        (obj, v) => ({
          ...obj,
          [v.id.toString()]: v,
        }),
        {} as { [key: string]: SongFileDto },
      ),
    [songFiles],
  );
  const autoCompleteData = useMemo(
    () =>
      Object.entries(
        songFiles.reduce(
          (obj, v) => {
            const group = v.musicType;

            if (!obj[group]) obj[group] = [];
            obj[group].push(v);
            return obj;
          },
          {} as { [key: string]: SongFileDto[] },
        ),
      ).map(([group, files]) => ({
        group: group.toUpperCase(),
        items: files.map((file) => ({
          label: `${file.key} | ${file.name}`,
          value: file.id.toString(),
        })),
        value: group,
      })),
    [songFiles],
  );

  return (
    <Select
      label="Select File"
      // style={{ minWidth: 350 }}
      onChange={(value) => (value ? onSelectFile(filesById[value]) : undefined)}
      data={autoCompleteData}
      value={selectedFile.id.toString()}
      allowDeselect={false}
    />
  );
};
