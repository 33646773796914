import {
  Center,
  Card,
  Title,
  TextInput,
  Button,
  Stack,
  Group,
} from '@mantine/core';
import { IconMusic } from '@tabler/icons';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthContext } from '../../context/auth-context';
import ErrorAlert from '../atoms/ErrorAlert';

export default function LoginPage() {
  const auth = useAuthContext();
  const location = useLocation();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  if (auth.user) {
    const params = new URLSearchParams(location.search);
    const returnTo = params.get('returnTo');
    if (returnTo) {
      return <Navigate to={returnTo} />;
    }
    return <Navigate to="/songs" />;
  }

  const handleSignIn: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    setLoading(true);
    setError(false);
    event.preventDefault();
    setError(!(await auth.signIn(username, password)));
    setLoading(false);
  };

  return (
    <Center style={{ height: '100vh' }}>
      <Card shadow="md" p="xl" style={{ maxWidth: 500, width: '95vw' }}>
        <Group justify="space-between">
          <Title order={4}>Login</Title>
          <IconMusic />
        </Group>
        <form onSubmit={handleSignIn}>
          <Stack>
            <TextInput
              label="Username"
              required
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
              disabled={loading}
            />
            <TextInput
              label="Password"
              type="password"
              autoComplete="current-password"
              required
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <Button loading={loading} type="submit">
              Login
            </Button>
            {error && <ErrorAlert>Bad username and/or password</ErrorAlert>}
          </Stack>
        </form>
      </Card>
    </Center>
  );
}
