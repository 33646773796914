/* tslint:disable */
/* eslint-disable */
/**
 * Sigma API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AddKeyToSongDto
 */
export interface AddKeyToSongDto {
    /**
     * 
     * @type {string}
     * @memberof AddKeyToSongDto
     */
    'key': AddKeyToSongDtoKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof AddKeyToSongDto
     */
    'name': string;
}

export const AddKeyToSongDtoKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type AddKeyToSongDtoKeyEnum = typeof AddKeyToSongDtoKeyEnum[keyof typeof AddKeyToSongDtoKeyEnum];

/**
 * 
 * @export
 * @interface AddSongTagDto
 */
export interface AddSongTagDto {
    /**
     * 
     * @type {string}
     * @memberof AddSongTagDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AddSongTagDto
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface ChangeSetOwnerDto
 */
export interface ChangeSetOwnerDto {
    /**
     * 
     * @type {string}
     * @memberof ChangeSetOwnerDto
     */
    'ownerUsername': string;
}
/**
 * 
 * @export
 * @interface CreateSetDto
 */
export interface CreateSetDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSetDto
     */
    'label': string;
    /**
     * 
     * @type {Array<CreateSetItemDto>}
     * @memberof CreateSetDto
     */
    'items': Array<CreateSetItemDto>;
}
/**
 * 
 * @export
 * @interface CreateSetItemDto
 */
export interface CreateSetItemDto {
    /**
     * 
     * @type {string}
     * @memberof CreateSetItemDto
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSetItemDto
     */
    'estimatedTimeSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSetItemDto
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateSetItemDto
     */
    'song'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSetItemDto
     */
    'chosenKey'?: CreateSetItemDtoChosenKeyEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateSetItemDto
     */
    'file'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateSetItemDto
     */
    'order'?: string;
}

export const CreateSetItemDtoChosenKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type CreateSetItemDtoChosenKeyEnum = typeof CreateSetItemDtoChosenKeyEnum[keyof typeof CreateSetItemDtoChosenKeyEnum];

/**
 * 
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LoginDto
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface MergeSongsDto
 */
export interface MergeSongsDto {
    /**
     * 
     * @type {number}
     * @memberof MergeSongsDto
     */
    'primaryId': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof MergeSongsDto
     */
    'otherIds': Array<number>;
}
/**
 * 
 * @export
 * @interface RegisterDto
 */
export interface RegisterDto {
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof RegisterDto
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof RegisterDto
     */
    'isAdmin': boolean;
}
/**
 * 
 * @export
 * @interface ReorderSetItemsDto
 */
export interface ReorderSetItemsDto {
    /**
     * 
     * @type {Array<number>}
     * @memberof ReorderSetItemsDto
     */
    'itemIds': Array<number>;
}
/**
 * 
 * @export
 * @interface ResetPasswordDto
 */
export interface ResetPasswordDto {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface SetCollectionDto
 */
export interface SetCollectionDto {
    /**
     * 
     * @type {number}
     * @memberof SetCollectionDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SetCollectionDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof SetCollectionDto
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<SetDto>}
     * @memberof SetCollectionDto
     */
    'sets'?: Array<SetDto>;
    /**
     * 
     * @type {number}
     * @memberof SetCollectionDto
     */
    'setsCount': number;
}
/**
 * 
 * @export
 * @interface SetDto
 */
export interface SetDto {
    /**
     * 
     * @type {number}
     * @memberof SetDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SetDto
     */
    'label': string;
    /**
     * 
     * @type {Array<SetItemDto>}
     * @memberof SetDto
     */
    'items': Array<SetItemDto>;
    /**
     * 
     * @type {UserDto}
     * @memberof SetDto
     */
    'owner': UserDto;
    /**
     * 
     * @type {string}
     * @memberof SetDto
     */
    'createdAt': string;
    /**
     * 
     * @type {Array<SetCollectionDto>}
     * @memberof SetDto
     */
    'collections'?: Array<SetCollectionDto>;
    /**
     * 
     * @type {number}
     * @memberof SetDto
     */
    'collectionCount': number;
}
/**
 * 
 * @export
 * @interface SetItemDto
 */
export interface SetItemDto {
    /**
     * 
     * @type {number}
     * @memberof SetItemDto
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof SetItemDto
     */
    'estimatedTimeSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof SetItemDto
     */
    'notes'?: string;
    /**
     * 
     * @type {string}
     * @memberof SetItemDto
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof SetItemDto
     */
    'index': number;
    /**
     * 
     * @type {SongDto}
     * @memberof SetItemDto
     */
    'song'?: SongDto;
    /**
     * 
     * @type {string}
     * @memberof SetItemDto
     */
    'chosenKey'?: SetItemDtoChosenKeyEnum;
    /**
     * 
     * @type {SongFileDto}
     * @memberof SetItemDto
     */
    'file'?: SongFileDto;
    /**
     * 
     * @type {string}
     * @memberof SetItemDto
     */
    'order'?: string;
}

export const SetItemDtoChosenKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type SetItemDtoChosenKeyEnum = typeof SetItemDtoChosenKeyEnum[keyof typeof SetItemDtoChosenKeyEnum];

/**
 * 
 * @export
 * @interface SetPreferredKeyDto
 */
export interface SetPreferredKeyDto {
    /**
     * 
     * @type {string}
     * @memberof SetPreferredKeyDto
     */
    'key': SetPreferredKeyDtoKeyEnum;
}

export const SetPreferredKeyDtoKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type SetPreferredKeyDtoKeyEnum = typeof SetPreferredKeyDtoKeyEnum[keyof typeof SetPreferredKeyDtoKeyEnum];

/**
 * 
 * @export
 * @interface SongDto
 */
export interface SongDto {
    /**
     * 
     * @type {number}
     * @memberof SongDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SongDto
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SongDto
     */
    'altTitles': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SongDto
     */
    'preferredKey': SongDtoPreferredKeyEnum;
    /**
     * 
     * @type {Array<SongKeyDto>}
     * @memberof SongDto
     */
    'keys': Array<SongKeyDto>;
    /**
     * 
     * @type {Array<SongTagDto>}
     * @memberof SongDto
     */
    'tags': Array<SongTagDto>;
    /**
     * 
     * @type {Array<SongFileDto>}
     * @memberof SongDto
     */
    'files': Array<SongFileDto>;
    /**
     * 
     * @type {string}
     * @memberof SongDto
     */
    'youtubeVideoId'?: string;
}

export const SongDtoPreferredKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type SongDtoPreferredKeyEnum = typeof SongDtoPreferredKeyEnum[keyof typeof SongDtoPreferredKeyEnum];

/**
 * 
 * @export
 * @interface SongFileDto
 */
export interface SongFileDto {
    /**
     * 
     * @type {number}
     * @memberof SongFileDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SongFileDto
     */
    'musicType': SongFileDtoMusicTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SongFileDto
     */
    'key': SongFileDtoKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SongFileDto
     */
    'name': string;
}

export const SongFileDtoMusicTypeEnum = {
    Unknown: 'unknown',
    Chord: 'chord',
    Lead: 'lead',
    Choral: 'choral',
    Lyrics: 'lyrics',
    Other: 'other'
} as const;

export type SongFileDtoMusicTypeEnum = typeof SongFileDtoMusicTypeEnum[keyof typeof SongFileDtoMusicTypeEnum];
export const SongFileDtoKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type SongFileDtoKeyEnum = typeof SongFileDtoKeyEnum[keyof typeof SongFileDtoKeyEnum];

/**
 * 
 * @export
 * @interface SongKeyDto
 */
export interface SongKeyDto {
    /**
     * 
     * @type {number}
     * @memberof SongKeyDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SongKeyDto
     */
    'key': SongKeyDtoKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof SongKeyDto
     */
    'name': string;
}

export const SongKeyDtoKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type SongKeyDtoKeyEnum = typeof SongKeyDtoKeyEnum[keyof typeof SongKeyDtoKeyEnum];

/**
 * 
 * @export
 * @interface SongTagDto
 */
export interface SongTagDto {
    /**
     * 
     * @type {number}
     * @memberof SongTagDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof SongTagDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof SongTagDto
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface UpdatePasswordDto
 */
export interface UpdatePasswordDto {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    'oldPassword': string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordDto
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface UpdateSetCollectionDto
 */
export interface UpdateSetCollectionDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSetCollectionDto
     */
    'label'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSetCollectionDto
     */
    'setIds'?: Array<number>;
}
/**
 * 
 * @export
 * @interface UpdateSetDto
 */
export interface UpdateSetDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSetDto
     */
    'label'?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateSetDto
     */
    'collectionIds'?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateSetDto
     */
    'newCollectionNames'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateSetItemDto
 */
export interface UpdateSetItemDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSetItemDto
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSetItemDto
     */
    'estimatedTimeSeconds'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSetItemDto
     */
    'notes'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSetItemDto
     */
    'song'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSetItemDto
     */
    'chosenKey'?: UpdateSetItemDtoChosenKeyEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateSetItemDto
     */
    'file'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateSetItemDto
     */
    'order'?: string;
}

export const UpdateSetItemDtoChosenKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type UpdateSetItemDtoChosenKeyEnum = typeof UpdateSetItemDtoChosenKeyEnum[keyof typeof UpdateSetItemDtoChosenKeyEnum];

/**
 * 
 * @export
 * @interface UpdateSongFileDto
 */
export interface UpdateSongFileDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSongFileDto
     */
    'key': UpdateSongFileDtoKeyEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateSongFileDto
     */
    'musicType': UpdateSongFileDtoMusicTypeEnum;
}

export const UpdateSongFileDtoKeyEnum = {
    Unknown: 'unknown',
    CFlat: 'C Flat',
    C: 'C',
    CSharp: 'C Sharp',
    DFlat: 'D Flat',
    D: 'D',
    DSharp: 'D Sharp',
    EFlat: 'E Flat',
    E: 'E',
    ESharp: 'E Sharp',
    FFlat: 'F Flat',
    F: 'F',
    FSharp: 'F Sharp',
    GFlat: 'G Flat',
    G: 'G',
    GSharp: 'G Sharp',
    AFlat: 'A Flat',
    A: 'A',
    ASharp: 'A Sharp',
    BFlat: 'B Flat',
    B: 'B',
    BSharp: 'B Sharp'
} as const;

export type UpdateSongFileDtoKeyEnum = typeof UpdateSongFileDtoKeyEnum[keyof typeof UpdateSongFileDtoKeyEnum];
export const UpdateSongFileDtoMusicTypeEnum = {
    Unknown: 'unknown',
    Chord: 'chord',
    Lead: 'lead',
    Choral: 'choral',
    Lyrics: 'lyrics',
    Other: 'other'
} as const;

export type UpdateSongFileDtoMusicTypeEnum = typeof UpdateSongFileDtoMusicTypeEnum[keyof typeof UpdateSongFileDtoMusicTypeEnum];

/**
 * 
 * @export
 * @interface UpdateTitlesDto
 */
export interface UpdateTitlesDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateTitlesDto
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTitlesDto
     */
    'altTitles': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateUserAdminDto
 */
export interface UpdateUserAdminDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserAdminDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserAdminDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserAdminDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateUserAdminDto
     */
    'isAdmin': boolean;
}
/**
 * 
 * @export
 * @interface UpdateUserDto
 */
export interface UpdateUserDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDto
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateYoutubeIdDto
 */
export interface UpdateYoutubeIdDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateYoutubeIdDto
     */
    'embedId': string;
}
/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    'isAdmin': boolean;
}

/**
 * AdminApi - axios parameter creator
 * @export
 */
export const AdminApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerInitializeDatabase: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/initialize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MergeSongsDto} mergeSongsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerMergeSongs: async (mergeSongsDto: MergeSongsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mergeSongsDto' is not null or undefined
            assertParamExists('adminControllerMergeSongs', 'mergeSongsDto', mergeSongsDto)
            const localVarPath = `/api/admin/merge-songs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(mergeSongsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerPersistDatabase: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/admin/persist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminApi - functional programming interface
 * @export
 */
export const AdminApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AdminApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerInitializeDatabase(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SongDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerInitializeDatabase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MergeSongsDto} mergeSongsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerMergeSongs(mergeSongsDto: MergeSongsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerMergeSongs(mergeSongsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async adminControllerPersistDatabase(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SongDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.adminControllerPersistDatabase(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AdminApi - factory interface
 * @export
 */
export const AdminApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AdminApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerInitializeDatabase(options?: any): AxiosPromise<Array<SongDto>> {
            return localVarFp.adminControllerInitializeDatabase(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MergeSongsDto} mergeSongsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerMergeSongs(mergeSongsDto: MergeSongsDto, options?: any): AxiosPromise<void> {
            return localVarFp.adminControllerMergeSongs(mergeSongsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        adminControllerPersistDatabase(options?: any): AxiosPromise<Array<SongDto>> {
            return localVarFp.adminControllerPersistDatabase(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AdminApi - object-oriented interface
 * @export
 * @class AdminApi
 * @extends {BaseAPI}
 */
export class AdminApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminControllerInitializeDatabase(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminControllerInitializeDatabase(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MergeSongsDto} mergeSongsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminControllerMergeSongs(mergeSongsDto: MergeSongsDto, options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminControllerMergeSongs(mergeSongsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminApi
     */
    public adminControllerPersistDatabase(options?: AxiosRequestConfig) {
        return AdminApiFp(this.configuration).adminControllerPersistDatabase(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword: async (updatePasswordDto: UpdatePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePasswordDto' is not null or undefined
            assertParamExists('authControllerChangePassword', 'updatePasswordDto', updatePasswordDto)
            const localVarPath = `/api/auth/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin: async (loginDto: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginDto' is not null or undefined
            assertParamExists('authControllerLogin', 'loginDto', loginDto)
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterNewUser: async (registerDto: RegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registerDto' is not null or undefined
            assertParamExists('authControllerRegisterNewUser', 'registerDto', registerDto)
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword: async (resetPasswordDto: ResetPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordDto' is not null or undefined
            assertParamExists('authControllerResetPassword', 'resetPasswordDto', resetPasswordDto)
            const localVarPath = `/api/auth/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerChangePassword(updatePasswordDto: UpdatePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerChangePassword(updatePasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogin(loginDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerLogout(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerLogout(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerRegisterNewUser(registerDto: RegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerRegisterNewUser(registerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authControllerResetPassword(resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authControllerResetPassword(resetPasswordDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @param {UpdatePasswordDto} updatePasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerChangePassword(updatePasswordDto: UpdatePasswordDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.authControllerChangePassword(updatePasswordDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} loginDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogin(loginDto: LoginDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.authControllerLogin(loginDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerLogout(options?: any): AxiosPromise<string> {
            return localVarFp.authControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDto} registerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerRegisterNewUser(registerDto: RegisterDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.authControllerRegisterNewUser(registerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordDto} resetPasswordDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authControllerResetPassword(resetPasswordDto: ResetPasswordDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.authControllerResetPassword(resetPasswordDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {UpdatePasswordDto} updatePasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerChangePassword(updatePasswordDto: UpdatePasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerChangePassword(updatePasswordDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoginDto} loginDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogin(loginDto: LoginDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogin(loginDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerLogout(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegisterDto} registerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerRegisterNewUser(registerDto: RegisterDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerRegisterNewUser(registerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ResetPasswordDto} resetPasswordDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authControllerResetPassword(resetPasswordDto: ResetPasswordDto, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).authControllerResetPassword(resetPasswordDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SetsApi - axios parameter creator
 * @export
 */
export const SetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {CreateSetItemDto} createSetItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerAddSetItem: async (id: number, createSetItemDto: CreateSetItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setControllerAddSetItem', 'id', id)
            // verify required parameter 'createSetItemDto' is not null or undefined
            assertParamExists('setControllerAddSetItem', 'createSetItemDto', createSetItemDto)
            const localVarPath = `/api/sets/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSetItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeSetOwnerDto} changeSetOwnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerChangeSetOwner: async (id: number, changeSetOwnerDto: ChangeSetOwnerDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setControllerChangeSetOwner', 'id', id)
            // verify required parameter 'changeSetOwnerDto' is not null or undefined
            assertParamExists('setControllerChangeSetOwner', 'changeSetOwnerDto', changeSetOwnerDto)
            const localVarPath = `/api/sets/{id}/owner`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(changeSetOwnerDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSetDto} createSetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerCreateSet: async (createSetDto: CreateSetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSetDto' is not null or undefined
            assertParamExists('setControllerCreateSet', 'createSetDto', createSetDto)
            const localVarPath = `/api/sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerDeleteSet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setControllerDeleteSet', 'id', id)
            const localVarPath = `/api/sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerDeleteSetItem: async (itemId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('setControllerDeleteSetItem', 'itemId', itemId)
            const localVarPath = `/api/sets/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerGetSet: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setControllerGetSet', 'id', id)
            const localVarPath = `/api/sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerGetSetCollection: async (collectionId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('setControllerGetSetCollection', 'collectionId', collectionId)
            const localVarPath = `/api/sets/collections/{collectionId}`
                .replace(`{${"collectionId"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} sets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerGetSetCollections: async (sets: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sets' is not null or undefined
            assertParamExists('setControllerGetSetCollections', 'sets', sets)
            const localVarPath = `/api/sets/collections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sets !== undefined) {
                localVarQueryParameter['sets'] = sets;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} collections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerListSets: async (collections: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collections' is not null or undefined
            assertParamExists('setControllerListSets', 'collections', collections)
            const localVarPath = `/api/sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (collections !== undefined) {
                localVarQueryParameter['collections'] = collections;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ReorderSetItemsDto} reorderSetItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerReorderSetItems: async (id: number, reorderSetItemsDto: ReorderSetItemsDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setControllerReorderSetItems', 'id', id)
            // verify required parameter 'reorderSetItemsDto' is not null or undefined
            assertParamExists('setControllerReorderSetItems', 'reorderSetItemsDto', reorderSetItemsDto)
            const localVarPath = `/api/sets/{id}/reorder-items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reorderSetItemsDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSetDto} updateSetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerUpdateSet: async (id: number, updateSetDto: UpdateSetDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setControllerUpdateSet', 'id', id)
            // verify required parameter 'updateSetDto' is not null or undefined
            assertParamExists('setControllerUpdateSet', 'updateSetDto', updateSetDto)
            const localVarPath = `/api/sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSetDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} collectionId 
         * @param {UpdateSetCollectionDto} updateSetCollectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerUpdateSetCollection: async (collectionId: number, updateSetCollectionDto: UpdateSetCollectionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'collectionId' is not null or undefined
            assertParamExists('setControllerUpdateSetCollection', 'collectionId', collectionId)
            // verify required parameter 'updateSetCollectionDto' is not null or undefined
            assertParamExists('setControllerUpdateSetCollection', 'updateSetCollectionDto', updateSetCollectionDto)
            const localVarPath = `/api/sets/collections/{collectionId}`
                .replace(`{${"collectionId"}}`, encodeURIComponent(String(collectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSetCollectionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} itemId 
         * @param {UpdateSetItemDto} updateSetItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerUpdateSetItem: async (itemId: number, updateSetItemDto: UpdateSetItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('setControllerUpdateSetItem', 'itemId', itemId)
            // verify required parameter 'updateSetItemDto' is not null or undefined
            assertParamExists('setControllerUpdateSetItem', 'updateSetItemDto', updateSetItemDto)
            const localVarPath = `/api/sets/items/{itemId}`
                .replace(`{${"itemId"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSetItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SetsApi - functional programming interface
 * @export
 */
export const SetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SetsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {CreateSetItemDto} createSetItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerAddSetItem(id: number, createSetItemDto: CreateSetItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerAddSetItem(id, createSetItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeSetOwnerDto} changeSetOwnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerChangeSetOwner(id: number, changeSetOwnerDto: ChangeSetOwnerDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerChangeSetOwner(id, changeSetOwnerDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSetDto} createSetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerCreateSet(createSetDto: CreateSetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerCreateSet(createSetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerDeleteSet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerDeleteSet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerDeleteSetItem(itemId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerDeleteSetItem(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerGetSet(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerGetSet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerGetSetCollection(collectionId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetCollectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerGetSetCollection(collectionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} sets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerGetSetCollections(sets: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SetCollectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerGetSetCollections(sets, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {boolean} collections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerListSets(collections: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SetDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerListSets(collections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ReorderSetItemsDto} reorderSetItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerReorderSetItems(id: number, reorderSetItemsDto: ReorderSetItemsDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerReorderSetItems(id, reorderSetItemsDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSetDto} updateSetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerUpdateSet(id: number, updateSetDto: UpdateSetDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerUpdateSet(id, updateSetDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} collectionId 
         * @param {UpdateSetCollectionDto} updateSetCollectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerUpdateSetCollection(collectionId: number, updateSetCollectionDto: UpdateSetCollectionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetCollectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerUpdateSetCollection(collectionId, updateSetCollectionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} itemId 
         * @param {UpdateSetItemDto} updateSetItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setControllerUpdateSetItem(itemId: number, updateSetItemDto: UpdateSetItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SetItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setControllerUpdateSetItem(itemId, updateSetItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SetsApi - factory interface
 * @export
 */
export const SetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SetsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {CreateSetItemDto} createSetItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerAddSetItem(id: number, createSetItemDto: CreateSetItemDto, options?: any): AxiosPromise<SetItemDto> {
            return localVarFp.setControllerAddSetItem(id, createSetItemDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ChangeSetOwnerDto} changeSetOwnerDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerChangeSetOwner(id: number, changeSetOwnerDto: ChangeSetOwnerDto, options?: any): AxiosPromise<SetDto> {
            return localVarFp.setControllerChangeSetOwner(id, changeSetOwnerDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSetDto} createSetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerCreateSet(createSetDto: CreateSetDto, options?: any): AxiosPromise<SetDto> {
            return localVarFp.setControllerCreateSet(createSetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerDeleteSet(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.setControllerDeleteSet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerDeleteSetItem(itemId: number, options?: any): AxiosPromise<void> {
            return localVarFp.setControllerDeleteSetItem(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerGetSet(id: number, options?: any): AxiosPromise<SetDto> {
            return localVarFp.setControllerGetSet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} collectionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerGetSetCollection(collectionId: number, options?: any): AxiosPromise<SetCollectionDto> {
            return localVarFp.setControllerGetSetCollection(collectionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} sets 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerGetSetCollections(sets: boolean, options?: any): AxiosPromise<Array<SetCollectionDto>> {
            return localVarFp.setControllerGetSetCollections(sets, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} collections 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerListSets(collections: boolean, options?: any): AxiosPromise<Array<SetDto>> {
            return localVarFp.setControllerListSets(collections, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ReorderSetItemsDto} reorderSetItemsDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerReorderSetItems(id: number, reorderSetItemsDto: ReorderSetItemsDto, options?: any): AxiosPromise<void> {
            return localVarFp.setControllerReorderSetItems(id, reorderSetItemsDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSetDto} updateSetDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerUpdateSet(id: number, updateSetDto: UpdateSetDto, options?: any): AxiosPromise<SetDto> {
            return localVarFp.setControllerUpdateSet(id, updateSetDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} collectionId 
         * @param {UpdateSetCollectionDto} updateSetCollectionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerUpdateSetCollection(collectionId: number, updateSetCollectionDto: UpdateSetCollectionDto, options?: any): AxiosPromise<SetCollectionDto> {
            return localVarFp.setControllerUpdateSetCollection(collectionId, updateSetCollectionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} itemId 
         * @param {UpdateSetItemDto} updateSetItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setControllerUpdateSetItem(itemId: number, updateSetItemDto: UpdateSetItemDto, options?: any): AxiosPromise<SetItemDto> {
            return localVarFp.setControllerUpdateSetItem(itemId, updateSetItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SetsApi - object-oriented interface
 * @export
 * @class SetsApi
 * @extends {BaseAPI}
 */
export class SetsApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {CreateSetItemDto} createSetItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerAddSetItem(id: number, createSetItemDto: CreateSetItemDto, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerAddSetItem(id, createSetItemDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ChangeSetOwnerDto} changeSetOwnerDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerChangeSetOwner(id: number, changeSetOwnerDto: ChangeSetOwnerDto, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerChangeSetOwner(id, changeSetOwnerDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSetDto} createSetDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerCreateSet(createSetDto: CreateSetDto, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerCreateSet(createSetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerDeleteSet(id: number, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerDeleteSet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerDeleteSetItem(itemId: number, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerDeleteSetItem(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerGetSet(id: number, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerGetSet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} collectionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerGetSetCollection(collectionId: number, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerGetSetCollection(collectionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} sets 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerGetSetCollections(sets: boolean, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerGetSetCollections(sets, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} collections 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerListSets(collections: boolean, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerListSets(collections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {ReorderSetItemsDto} reorderSetItemsDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerReorderSetItems(id: number, reorderSetItemsDto: ReorderSetItemsDto, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerReorderSetItems(id, reorderSetItemsDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateSetDto} updateSetDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerUpdateSet(id: number, updateSetDto: UpdateSetDto, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerUpdateSet(id, updateSetDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} collectionId 
     * @param {UpdateSetCollectionDto} updateSetCollectionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerUpdateSetCollection(collectionId: number, updateSetCollectionDto: UpdateSetCollectionDto, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerUpdateSetCollection(collectionId, updateSetCollectionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} itemId 
     * @param {UpdateSetItemDto} updateSetItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SetsApi
     */
    public setControllerUpdateSetItem(itemId: number, updateSetItemDto: UpdateSetItemDto, options?: AxiosRequestConfig) {
        return SetsApiFp(this.configuration).setControllerUpdateSetItem(itemId, updateSetItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SongsApi - axios parameter creator
 * @export
 */
export const SongsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {AddKeyToSongDto} addKeyToSongDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerAddKeyToSong: async (id: number, addKeyToSongDto: AddKeyToSongDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerAddKeyToSong', 'id', id)
            // verify required parameter 'addKeyToSongDto' is not null or undefined
            assertParamExists('songsControllerAddKeyToSong', 'addKeyToSongDto', addKeyToSongDto)
            const localVarPath = `/api/songs/{id}/keys`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addKeyToSongDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {AddSongTagDto} addSongTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerAddTagToSong: async (id: number, addSongTagDto: AddSongTagDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerAddTagToSong', 'id', id)
            // verify required parameter 'addSongTagDto' is not null or undefined
            assertParamExists('songsControllerAddTagToSong', 'addSongTagDto', addSongTagDto)
            const localVarPath = `/api/songs/{id}/tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addSongTagDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerDeleteSong: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerDeleteSong', 'id', id)
            const localVarPath = `/api/songs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerDeleteSongFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerDeleteSongFile', 'id', id)
            const localVarPath = `/api/songs/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetAllTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/songs/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} count 
         * @param {Array<string>} include 
         * @param {Array<string>} exclude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetRandomSongs: async (count: number, include: Array<string>, exclude: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'count' is not null or undefined
            assertParamExists('songsControllerGetRandomSongs', 'count', count)
            // verify required parameter 'include' is not null or undefined
            assertParamExists('songsControllerGetRandomSongs', 'include', include)
            // verify required parameter 'exclude' is not null or undefined
            assertParamExists('songsControllerGetRandomSongs', 'exclude', exclude)
            const localVarPath = `/api/songs/shuffle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            if (include) {
                localVarQueryParameter['include'] = include;
            }

            if (exclude) {
                localVarQueryParameter['exclude'] = exclude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetSong: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerGetSong', 'id', id)
            const localVarPath = `/api/songs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetSongFile: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerGetSongFile', 'id', id)
            const localVarPath = `/api/songs/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerListSongs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/songs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {number} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerRemoveKeyFromSong: async (id: number, keyId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerRemoveKeyFromSong', 'id', id)
            // verify required parameter 'keyId' is not null or undefined
            assertParamExists('songsControllerRemoveKeyFromSong', 'keyId', keyId)
            const localVarPath = `/api/songs/{id}/keys/{keyId}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"keyId"}}`, encodeURIComponent(String(keyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerRemoveTagFromSong: async (id: number, tag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerRemoveTagFromSong', 'id', id)
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('songsControllerRemoveTagFromSong', 'tag', tag)
            const localVarPath = `/api/songs/{id}/tags/{tag}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"tag"}}`, encodeURIComponent(String(tag)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} title 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerSearchSongs: async (title: string, tag: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('songsControllerSearchSongs', 'title', title)
            // verify required parameter 'tag' is not null or undefined
            assertParamExists('songsControllerSearchSongs', 'tag', tag)
            const localVarPath = `/api/songs/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (tag !== undefined) {
                localVarQueryParameter['tag'] = tag;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {SetPreferredKeyDto} setPreferredKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerSetPreferredKeyForSong: async (id: number, setPreferredKeyDto: SetPreferredKeyDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerSetPreferredKeyForSong', 'id', id)
            // verify required parameter 'setPreferredKeyDto' is not null or undefined
            assertParamExists('songsControllerSetPreferredKeyForSong', 'setPreferredKeyDto', setPreferredKeyDto)
            const localVarPath = `/api/songs/{id}/key`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(setPreferredKeyDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateYoutubeIdDto} updateYoutubeIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerSetYoutubeIdForSong: async (id: number, updateYoutubeIdDto: UpdateYoutubeIdDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerSetYoutubeIdForSong', 'id', id)
            // verify required parameter 'updateYoutubeIdDto' is not null or undefined
            assertParamExists('songsControllerSetYoutubeIdForSong', 'updateYoutubeIdDto', updateYoutubeIdDto)
            const localVarPath = `/api/songs/{id}/youtube-id`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateYoutubeIdDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSongFileDto} updateSongFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUpdateSongFile: async (id: number, updateSongFileDto: UpdateSongFileDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerUpdateSongFile', 'id', id)
            // verify required parameter 'updateSongFileDto' is not null or undefined
            assertParamExists('songsControllerUpdateSongFile', 'updateSongFileDto', updateSongFileDto)
            const localVarPath = `/api/songs/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSongFileDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTitlesDto} updateTitlesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUpdateTitlesForSong: async (id: number, updateTitlesDto: UpdateTitlesDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerUpdateTitlesForSong', 'id', id)
            // verify required parameter 'updateTitlesDto' is not null or undefined
            assertParamExists('songsControllerUpdateTitlesForSong', 'updateTitlesDto', updateTitlesDto)
            const localVarPath = `/api/songs/{id}/titles`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTitlesDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<File>} [uploads] 
         * @param {string} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUploadFilesToSong: async (id: number, uploads?: Array<File>, files?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('songsControllerUploadFilesToSong', 'id', id)
            const localVarPath = `/api/songs/{id}/files`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (uploads) {
                uploads.forEach((element) => {
                    localVarFormParams.append('uploads', element as any);
                })
            }

    
            if (files !== undefined) { 
                localVarFormParams.append('files', files as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} title 
         * @param {Array<string>} [altTitles] 
         * @param {Array<string>} [tags] 
         * @param {Array<File>} [uploads] 
         * @param {string} [preferredKey] 
         * @param {string} [keys] 
         * @param {string} [files] 
         * @param {string} [youtubeVideoId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUploadNewSong: async (title: string, altTitles?: Array<string>, tags?: Array<string>, uploads?: Array<File>, preferredKey?: string, keys?: string, files?: string, youtubeVideoId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('songsControllerUploadNewSong', 'title', title)
            const localVarPath = `/api/songs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            if (altTitles) {
                localVarFormParams.append('altTitles', altTitles.join(COLLECTION_FORMATS.csv));
            }

                if (tags) {
                localVarFormParams.append('tags', tags.join(COLLECTION_FORMATS.csv));
            }

                if (uploads) {
                uploads.forEach((element) => {
                    localVarFormParams.append('uploads', element as any);
                })
            }

    
            if (title !== undefined) { 
                localVarFormParams.append('title', title as any);
            }
    
            if (preferredKey !== undefined) { 
                localVarFormParams.append('preferredKey', preferredKey as any);
            }
    
            if (keys !== undefined) { 
                localVarFormParams.append('keys', keys as any);
            }
    
            if (files !== undefined) { 
                localVarFormParams.append('files', files as any);
            }
    
            if (youtubeVideoId !== undefined) { 
                localVarFormParams.append('youtubeVideoId', youtubeVideoId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SongsApi - functional programming interface
 * @export
 */
export const SongsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SongsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {AddKeyToSongDto} addKeyToSongDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerAddKeyToSong(id: number, addKeyToSongDto: AddKeyToSongDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerAddKeyToSong(id, addKeyToSongDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {AddSongTagDto} addSongTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerAddTagToSong(id: number, addSongTagDto: AddSongTagDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerAddTagToSong(id, addSongTagDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerDeleteSong(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerDeleteSong(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerDeleteSongFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerDeleteSongFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerGetAllTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SongTagDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerGetAllTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} count 
         * @param {Array<string>} include 
         * @param {Array<string>} exclude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerGetRandomSongs(count: number, include: Array<string>, exclude: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SongDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerGetRandomSongs(count, include, exclude, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerGetSong(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerGetSong(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerGetSongFile(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerGetSongFile(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerListSongs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SongDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerListSongs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {number} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerRemoveKeyFromSong(id: number, keyId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerRemoveKeyFromSong(id, keyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerRemoveTagFromSong(id: number, tag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerRemoveTagFromSong(id, tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} title 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerSearchSongs(title: string, tag: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SongDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerSearchSongs(title, tag, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {SetPreferredKeyDto} setPreferredKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerSetPreferredKeyForSong(id: number, setPreferredKeyDto: SetPreferredKeyDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerSetPreferredKeyForSong(id, setPreferredKeyDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateYoutubeIdDto} updateYoutubeIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerSetYoutubeIdForSong(id: number, updateYoutubeIdDto: UpdateYoutubeIdDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerSetYoutubeIdForSong(id, updateYoutubeIdDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSongFileDto} updateSongFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerUpdateSongFile(id: number, updateSongFileDto: UpdateSongFileDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerUpdateSongFile(id, updateSongFileDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTitlesDto} updateTitlesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerUpdateTitlesForSong(id: number, updateTitlesDto: UpdateTitlesDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerUpdateTitlesForSong(id, updateTitlesDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<File>} [uploads] 
         * @param {string} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerUploadFilesToSong(id: number, uploads?: Array<File>, files?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerUploadFilesToSong(id, uploads, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} title 
         * @param {Array<string>} [altTitles] 
         * @param {Array<string>} [tags] 
         * @param {Array<File>} [uploads] 
         * @param {string} [preferredKey] 
         * @param {string} [keys] 
         * @param {string} [files] 
         * @param {string} [youtubeVideoId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async songsControllerUploadNewSong(title: string, altTitles?: Array<string>, tags?: Array<string>, uploads?: Array<File>, preferredKey?: string, keys?: string, files?: string, youtubeVideoId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SongDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.songsControllerUploadNewSong(title, altTitles, tags, uploads, preferredKey, keys, files, youtubeVideoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SongsApi - factory interface
 * @export
 */
export const SongsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SongsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {AddKeyToSongDto} addKeyToSongDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerAddKeyToSong(id: number, addKeyToSongDto: AddKeyToSongDto, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerAddKeyToSong(id, addKeyToSongDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {AddSongTagDto} addSongTagDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerAddTagToSong(id: number, addSongTagDto: AddSongTagDto, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerAddTagToSong(id, addSongTagDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerDeleteSong(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.songsControllerDeleteSong(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerDeleteSongFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.songsControllerDeleteSongFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetAllTags(options?: any): AxiosPromise<Array<SongTagDto>> {
            return localVarFp.songsControllerGetAllTags(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} count 
         * @param {Array<string>} include 
         * @param {Array<string>} exclude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetRandomSongs(count: number, include: Array<string>, exclude: Array<string>, options?: any): AxiosPromise<Array<SongDto>> {
            return localVarFp.songsControllerGetRandomSongs(count, include, exclude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetSong(id: number, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerGetSong(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerGetSongFile(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.songsControllerGetSongFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerListSongs(options?: any): AxiosPromise<Array<SongDto>> {
            return localVarFp.songsControllerListSongs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {number} keyId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerRemoveKeyFromSong(id: number, keyId: number, options?: any): AxiosPromise<void> {
            return localVarFp.songsControllerRemoveKeyFromSong(id, keyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerRemoveTagFromSong(id: number, tag: string, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerRemoveTagFromSong(id, tag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} title 
         * @param {string} tag 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerSearchSongs(title: string, tag: string, options?: any): AxiosPromise<Array<SongDto>> {
            return localVarFp.songsControllerSearchSongs(title, tag, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {SetPreferredKeyDto} setPreferredKeyDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerSetPreferredKeyForSong(id: number, setPreferredKeyDto: SetPreferredKeyDto, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerSetPreferredKeyForSong(id, setPreferredKeyDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateYoutubeIdDto} updateYoutubeIdDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerSetYoutubeIdForSong(id: number, updateYoutubeIdDto: UpdateYoutubeIdDto, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerSetYoutubeIdForSong(id, updateYoutubeIdDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateSongFileDto} updateSongFileDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUpdateSongFile(id: number, updateSongFileDto: UpdateSongFileDto, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerUpdateSongFile(id, updateSongFileDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateTitlesDto} updateTitlesDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUpdateTitlesForSong(id: number, updateTitlesDto: UpdateTitlesDto, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerUpdateTitlesForSong(id, updateTitlesDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Array<File>} [uploads] 
         * @param {string} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUploadFilesToSong(id: number, uploads?: Array<File>, files?: string, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerUploadFilesToSong(id, uploads, files, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} title 
         * @param {Array<string>} [altTitles] 
         * @param {Array<string>} [tags] 
         * @param {Array<File>} [uploads] 
         * @param {string} [preferredKey] 
         * @param {string} [keys] 
         * @param {string} [files] 
         * @param {string} [youtubeVideoId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        songsControllerUploadNewSong(title: string, altTitles?: Array<string>, tags?: Array<string>, uploads?: Array<File>, preferredKey?: string, keys?: string, files?: string, youtubeVideoId?: string, options?: any): AxiosPromise<SongDto> {
            return localVarFp.songsControllerUploadNewSong(title, altTitles, tags, uploads, preferredKey, keys, files, youtubeVideoId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SongsApi - object-oriented interface
 * @export
 * @class SongsApi
 * @extends {BaseAPI}
 */
export class SongsApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {AddKeyToSongDto} addKeyToSongDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerAddKeyToSong(id: number, addKeyToSongDto: AddKeyToSongDto, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerAddKeyToSong(id, addKeyToSongDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {AddSongTagDto} addSongTagDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerAddTagToSong(id: number, addSongTagDto: AddSongTagDto, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerAddTagToSong(id, addSongTagDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerDeleteSong(id: number, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerDeleteSong(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerDeleteSongFile(id: number, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerDeleteSongFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerGetAllTags(options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerGetAllTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} count 
     * @param {Array<string>} include 
     * @param {Array<string>} exclude 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerGetRandomSongs(count: number, include: Array<string>, exclude: Array<string>, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerGetRandomSongs(count, include, exclude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerGetSong(id: number, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerGetSong(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerGetSongFile(id: number, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerGetSongFile(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerListSongs(options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerListSongs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {number} keyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerRemoveKeyFromSong(id: number, keyId: number, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerRemoveKeyFromSong(id, keyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {string} tag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerRemoveTagFromSong(id: number, tag: string, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerRemoveTagFromSong(id, tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} title 
     * @param {string} tag 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerSearchSongs(title: string, tag: string, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerSearchSongs(title, tag, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {SetPreferredKeyDto} setPreferredKeyDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerSetPreferredKeyForSong(id: number, setPreferredKeyDto: SetPreferredKeyDto, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerSetPreferredKeyForSong(id, setPreferredKeyDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateYoutubeIdDto} updateYoutubeIdDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerSetYoutubeIdForSong(id: number, updateYoutubeIdDto: UpdateYoutubeIdDto, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerSetYoutubeIdForSong(id, updateYoutubeIdDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateSongFileDto} updateSongFileDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerUpdateSongFile(id: number, updateSongFileDto: UpdateSongFileDto, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerUpdateSongFile(id, updateSongFileDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {UpdateTitlesDto} updateTitlesDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerUpdateTitlesForSong(id: number, updateTitlesDto: UpdateTitlesDto, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerUpdateTitlesForSong(id, updateTitlesDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Array<File>} [uploads] 
     * @param {string} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerUploadFilesToSong(id: number, uploads?: Array<File>, files?: string, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerUploadFilesToSong(id, uploads, files, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} title 
     * @param {Array<string>} [altTitles] 
     * @param {Array<string>} [tags] 
     * @param {Array<File>} [uploads] 
     * @param {string} [preferredKey] 
     * @param {string} [keys] 
     * @param {string} [files] 
     * @param {string} [youtubeVideoId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SongsApi
     */
    public songsControllerUploadNewSong(title: string, altTitles?: Array<string>, tags?: Array<string>, uploads?: Array<File>, preferredKey?: string, keys?: string, files?: string, youtubeVideoId?: string, options?: AxiosRequestConfig) {
        return SongsApiFp(this.configuration).songsControllerUploadNewSong(title, altTitles, tags, uploads, preferredKey, keys, files, youtubeVideoId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteUser: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersControllerDeleteUser', 'username', username)
            const localVarPath = `/api/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateMe: async (updateUserDto: UpdateUserDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserDto' is not null or undefined
            assertParamExists('usersControllerUpdateMe', 'updateUserDto', updateUserDto)
            const localVarPath = `/api/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} username 
         * @param {UpdateUserAdminDto} updateUserAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUser: async (username: string, updateUserAdminDto: UpdateUserAdminDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersControllerUpdateUser', 'username', username)
            // verify required parameter 'updateUserAdminDto' is not null or undefined
            assertParamExists('usersControllerUpdateUser', 'updateUserAdminDto', updateUserAdminDto)
            const localVarPath = `/api/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserAdminDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerDeleteUser(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerDeleteUser(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetMe(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetMe(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerGetUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerGetUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateMe(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateMe(updateUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} username 
         * @param {UpdateUserAdminDto} updateUserAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersControllerUpdateUser(username: string, updateUserAdminDto: UpdateUserAdminDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersControllerUpdateUser(username, updateUserAdminDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerDeleteUser(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersControllerDeleteUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetMe(options?: any): AxiosPromise<UserDto> {
            return localVarFp.usersControllerGetMe(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerGetUsers(options?: any): AxiosPromise<Array<UserDto>> {
            return localVarFp.usersControllerGetUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserDto} updateUserDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateMe(updateUserDto: UpdateUserDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.usersControllerUpdateMe(updateUserDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} username 
         * @param {UpdateUserAdminDto} updateUserAdminDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersControllerUpdateUser(username: string, updateUserAdminDto: UpdateUserAdminDto, options?: any): AxiosPromise<UserDto> {
            return localVarFp.usersControllerUpdateUser(username, updateUserAdminDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerDeleteUser(username: string, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerDeleteUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetMe(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetMe(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerGetUsers(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerGetUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserDto} updateUserDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateMe(updateUserDto: UpdateUserDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateMe(updateUserDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} username 
     * @param {UpdateUserAdminDto} updateUserAdminDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersControllerUpdateUser(username: string, updateUserAdminDto: UpdateUserAdminDto, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersControllerUpdateUser(username, updateUserAdminDto, options).then((request) => request(this.axios, this.basePath));
    }
}


