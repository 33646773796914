import { Center, Group, SegmentedControl, TextInput } from '@mantine/core';
import {
  IconLayoutCards,
  IconList,
  IconListSearch,
  IconSearch,
  IconSortAscendingLetters,
  IconSortDescendingLetters,
} from '@tabler/icons';

type Props = {
  layout?: 'cards' | 'list';
  setLayout?: (x: 'cards' | 'list') => void;
  search: string;
  setSearch: (x: string) => void;
  sortBy: string;
  setSortBy: (x: string) => void;
};

export const SetsActionBar = ({
  layout = 'cards',
  search,
  setLayout,
  setSearch,
  setSortBy,
  sortBy,
}: Props) => {
  return (
    <Group justify="space-between" wrap="nowrap">
      <TextInput
        placeholder="Search..."
        leftSection={<IconSearch />}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ ':focus': { flexGrow: 1 } }}
      />
      <Group>
        <SegmentedControl
          color="green"
          onChange={setSortBy}
          value={sortBy}
          data={[
            {
              disabled: true,
              label: (
                <Center>
                  <IconListSearch />
                </Center>
              ),
              value: '',
            },
            {
              label: (
                <Center>
                  <IconSortAscendingLetters />
                </Center>
              ),
              value: 'title-ascending',
            },
            {
              label: (
                <Center>
                  <IconSortDescendingLetters />
                </Center>
              ),
              value: 'title-descending',
            },
          ]}
        />
        {layout && setLayout && (
          <SegmentedControl
            color="blue"
            onChange={(value) => setLayout(value as 'list' | 'cards')}
            value={layout}
            data={[
              {
                label: (
                  <Center>
                    <IconLayoutCards />
                  </Center>
                ),
                value: 'cards',
              },
              {
                label: (
                  <Center>
                    <IconList />
                  </Center>
                ),
                value: 'list',
              },
            ]}
          />
        )}
      </Group>
    </Group>
  );
};
