import { ActionIcon, Button, Modal, Stack, TextInput } from '@mantine/core';
import { IconPencil } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { UpdateTitlesDto, UpdateYoutubeIdDto } from '../../api/openapi';
import { AltTitlesEdit } from '../atoms/AltTitlesEdit';

type Props = {
  onSave: (title: UpdateTitlesDto, videoId?: UpdateYoutubeIdDto) => any;
  onOpen?: () => void;
  onClose?: () => void;
  primaryTitle?: string;
  altTitles?: string[];
  videoId?: string;
};

export const EditSongDetailsModal = ({
  altTitles,
  primaryTitle,
  videoId,
  ...props
}: Props) => {
  const [opened, setOpened] = useState(false);

  const [title, setTitle] = useState<string>(primaryTitle ?? '');
  const [otherTitles, setOtherTitles] = useState<string[]>(altTitles ?? []);
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>(videoId ?? '');

  useEffect(() => {
    if (primaryTitle) {
      setTitle(primaryTitle);
    }
  }, [primaryTitle]);

  useEffect(() => {
    if (altTitles) {
      setOtherTitles(altTitles);
    }
  }, [altTitles]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (title && otherTitles) {
      props.onSave(
        { altTitles: otherTitles, title },
        youtubeVideoId.length > 0 ? { embedId: youtubeVideoId } : undefined,
      );
      handleClose();
    }
  };
  const handleClose = () => {
    setOpened(false);
    if (props.onClose) props.onClose();
  };

  return (
    <>
      <Modal opened={opened} onClose={handleClose} title="Update song details">
        <form onSubmit={handleSubmit}>
          <Stack>
            <TextInput
              label="Primary Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            <AltTitlesEdit value={otherTitles} onChange={setOtherTitles} />
            <TextInput
              label="Youtube video id"
              value={youtubeVideoId}
              onChange={(e) => setYoutubeVideoId(e.target.value)}
            />
            <Button type="submit" fullWidth>
              Save Titles
            </Button>
          </Stack>
        </form>
      </Modal>

      <ActionIcon
        variant="subtle"
        onClick={() => {
          setOpened(true);
          if (props.onOpen) props.onOpen();
        }}
      >
        <IconPencil />
      </ActionIcon>
    </>
  );
};
