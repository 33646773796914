import { useRef, useEffect } from 'react';

function useTitle(title?: string, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    if (title) {
      document.title = `${title} | Sigma`;
    } else {
      document.title = 'Sigma';
    }
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [prevailOnUnmount],
  );
}

export default useTitle;
