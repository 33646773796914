import { Route, Routes } from 'react-router-dom';
import { USER_ROUTES, PRIVATE_ROUTES } from './routes';
import PrivateRoute from './components/navigation/PrivateRoute';
import LoginPage from './components/pages/LoginPage';
import { AdminRoute } from './components/navigation/AdminRoute';
import useTitle from './hooks/use-title';

export default function App() {
  const ALL_ROUTES = [...USER_ROUTES, ...PRIVATE_ROUTES];
  useTitle();
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="*" element={<PrivateRoute />}>
        {ALL_ROUTES.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              route.admin ? (
                <AdminRoute element={route.element} />
              ) : (
                route.element
              )
            }
          />
        ))}
        {ALL_ROUTES.map((route) =>
          route.children
            ? route.children.map((child) => (
                <Route
                  key={child.path}
                  path={route.path + '/' + child.path}
                  element={
                    route.admin || child.admin ? (
                      <AdminRoute element={child.element} />
                    ) : (
                      child.element
                    )
                  }
                />
              ))
            : null,
        )}
      </Route>
    </Routes>
  );
}
