import { Alert, Button, Group, Stack, Text, TextInput } from '@mantine/core';
import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import { UserDto } from '../../api/openapi';
import { useAuthContext } from '../../context/auth-context';
import ErrorAlert from '../atoms/ErrorAlert';

type Props = {
  userToReset?: UserDto;
  onSuccess?: () => void;
};
export default function ResetPassword({ onSuccess, userToReset }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [newPassword, setNewPassword] = useState<string>('');

  const auth = useAuthContext();

  if (!auth.user || !auth.user.isAdmin) {
    return <Navigate to="/login" />;
  }

  const changePassword: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    if (userToReset) {
      setError(false);
      setLoading(true);
      try {
        await SigmaApi.auth.authControllerResetPassword({
          newPassword,
          username: userToReset.username,
        });
        setSuccess(true);
        if (onSuccess) onSuccess();
        await auth.refreshSession();
        setNewPassword('');
      } catch (error) {
        setError(true);
      }
      setLoading(false);
    }
  };

  return (
    <form onSubmit={changePassword}>
      <input
        name="username"
        type="text"
        value={userToReset ? userToReset.username : ''}
        autoComplete="username"
        readOnly
        style={{ display: 'none' }}
      />
      <Stack>
        <Group grow>
          <Text>Reset Password for {userToReset?.username}</Text>
          <TextInput
            label="New Password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            autoComplete="new-password"
          />
        </Group>
        <Group justify="right">
          {success && <Alert style={{ flexGrow: 1 }}>Password updated</Alert>}
          {error && (
            <ErrorAlert style={{ flexGrow: 1 }}>
              Failed to update password
            </ErrorAlert>
          )}
          <Button loading={loading} type="submit">
            Update password
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
