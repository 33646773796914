import {
  ActionIcon,
  Center,
  Group,
  Overlay,
  Select,
  Stack,
  Text,
  Tooltip,
} from '@mantine/core';
import { IconKey, IconTrash, IconUpload } from '@tabler/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  SongFileDtoKeyEnum,
  SongFileDtoMusicTypeEnum,
} from '../../api/openapi';
import { KEYS } from '../../constants/keys';
import { MUSIC_TYPES } from '../../constants/music-type';
import { FileType } from '../atoms/FileType';

type Props = {
  onDrop?: (files: File[]) => void;
  value?: SongFile[];
  onChange?: React.Dispatch<React.SetStateAction<SongFile[]>>;
};

export type SongFile = {
  name: string;
  key: SongFileDtoKeyEnum;
  file: File;
  musicType: SongFileDtoMusicTypeEnum;
};

export const FileDropzone = ({ onChange, onDrop, value }: Props) => {
  const [files, setFiles] = useState<SongFile[]>(value ? value : []);

  useEffect(() => {
    if (value) {
      setFiles(value);
    }
  }, [value]);

  const handleChange = useCallback<
    React.Dispatch<React.SetStateAction<SongFile[]>>
  >(
    (files) => {
      if (onChange) {
        onChange(files);
      } else {
        setFiles(files);
      }
    },
    [onChange],
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      handleChange((old) => [
        ...old,
        ...acceptedFiles.map((f) => {
          let name = f.name;
          const splitName = f.name.split('.');
          let inc = 1;
          // eslint-disable-next-line
          while (old.find((o) => o.name === name)) {
            name = `${splitName.slice(0, -1).join('.')} [${inc}].${
              splitName[splitName.length - 1]
            }`;
            inc++;
          }
          return {
            file: f,
            key: SongFileDtoKeyEnum.Unknown,
            musicType: SongFileDtoMusicTypeEnum.Unknown,
            name: name,
          };
        }),
      ]);
      if (onDrop) onDrop(acceptedFiles);
    },
    [onDrop, handleChange],
  );
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const removeFile = (file: SongFile) => {
    handleChange((old) => old.filter((f) => f.name !== file.name));
  };

  const updateFileKey = (file: SongFile, value: string | null) => {
    handleChange((old) => {
      const index = old.findIndex((f) => file.name === f.name);
      file.key = value
        ? (value as SongFileDtoKeyEnum)
        : SongFileDtoKeyEnum.Unknown;
      old[index] = file;
      return [...old];
    });
  };

  const updateFileMusicType = (file: SongFile, value: string | null) => {
    handleChange((old) => {
      const index = old.findIndex((f) => file.name === f.name);
      file.musicType = value
        ? (value as SongFileDtoMusicTypeEnum)
        : SongFileDtoMusicTypeEnum.Unknown;
      old[index] = file;
      return [...old];
    });
  };

  return (
    <Group style={{ maxWidth: 1000 }} align="start">
      <div {...getRootProps()} style={{ position: 'relative' }}>
        <input {...getInputProps()} />
        {isDragActive && <Overlay opacity={0.3} color="black" />}
        <Tooltip
          label="Upload Files"
          position="bottom"
          offset={-45}
          color="gray"
        >
          <Center
            style={{
              ':hover': {
                cursor: 'pointer',
              },
              borderColor: 'lightgray',
              borderRadius: 5,
              borderStyle: 'dashed',
              borderWidth: 3,
              height: 150,
              padding: 10,
              width: 150,
            }}
          >
            <Stack>
              <IconUpload size={50} color="gray" />
            </Stack>
          </Center>
        </Tooltip>
      </div>
      <Group style={{ flexDirection: 'column' }} align="start">
        {files.map((file) => (
          <Group key={file.name}>
            <Select
              style={{ width: 55 }}
              leftSection={<IconKey size={15} />}
              leftSectionWidth={23}
              rightSection={<IconKey size={20} />}
              rightSectionWidth={0}
              rightSectionProps={{ style: { pointerEvents: 'none' } }}
              value={file.key}
              onChange={(value) => updateFileKey(file, value)}
              data={KEYS}
            />
            <Select
              style={{ width: 80 }}
              rightSection={<IconKey size={20} />}
              rightSectionWidth={0}
              rightSectionProps={{ style: { pointerEvents: 'none' } }}
              value={file.musicType}
              onChange={(value) => updateFileMusicType(file, value)}
              data={MUSIC_TYPES}
            />
            <FileType type={file.file.type} />
            <Text style={{ maxWidth: 400 }} lineClamp={1}>
              {file.name} ({(file.file.size / (1000 * 1000)).toFixed(2)} MB)
            </Text>
            <ActionIcon
              variant="subtle"
              color="red"
              onClick={() => removeFile(file)}
            >
              <IconTrash />
            </ActionIcon>
          </Group>
        ))}
      </Group>
    </Group>
  );
};
