import { Box } from '@mantine/core';
import { useEffect, useState } from 'react';
import { SongTagDto } from '../../api/openapi';
import { CreatableMultiSelect } from './CreatableMultiSelect';

type Props = {
  value?: string[];
  onChange?: React.Dispatch<React.SetStateAction<string[]>>;
  existingTags?: SongTagDto[];
  label?: string;
  style?: any;
  creatable?: boolean;
};

export const TagsInput = ({
  creatable,
  existingTags,
  label = 'Tags',
  onChange,
  style,
  value,
}: Props) => {
  const [data, setData] = useState<
    ({ value: string; label: string } & Partial<SongTagDto>)[]
  >(
    existingTags
      ? existingTags.map((v) => ({ label: v.name, value: v.name, ...v }))
      : [],
  );
  const [selected, setSelected] = useState<string[]>(value ?? []);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    if (existingTags) {
      setData(
        existingTags.map((v) => ({ label: v.name, value: v.name, ...v })),
      );
    }
  }, [existingTags]);

  const handleChange = (value: string[]) => {
    if (onChange) {
      onChange(value);
    } else {
      setSelected(value);
    }
  };

  return (
    <Box style={style}>
      <CreatableMultiSelect
        data={data}
        label={label}
        creatable={creatable}
        value={selected.map((s) => ({ label: s, value: s }))}
        onChange={(val) => handleChange(val.map((v) => v.value))}
        onCreate={(query) => {
          const item = { color: 'blue', label: query, value: query };
          setData((old) => [...old, item]);
          return item;
        }}
      />
    </Box>
  );
};
