import { Button, LoadingOverlay, Modal } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { useState } from 'react';
import { CreateSetItemDto } from '../../api/openapi';
import { AddSetItemForm } from '../molecules/AddSetItemForm';

type Props = {
  onOpen?: () => void;
  onClose?: () => void;
  onSubmit: (createSetItemDto: CreateSetItemDto) => Promise<void>;
};

export const AddSetItemModal = ({ onClose, onOpen, onSubmit }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
    if (onOpen) onOpen();
  };

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  const handleSubmit = async (createSetItemDto: CreateSetItemDto) => {
    setLoading(true);
    await onSubmit(createSetItemDto);
    setLoading(false);
    handleClose();
  };
  return (
    <>
      <Button
        size="xs"
        variant="outline"
        leftSection={<IconPlus size={16} />}
        onClick={() => handleOpen()}
      >
        Add item
      </Button>
      <Modal opened={open} onClose={() => handleClose()} title="Add a new item">
        <LoadingOverlay visible={loading} />
        <AddSetItemForm onSubmit={handleSubmit} />
      </Modal>
    </>
  );
};
