import Chord, {
  ChordDescription,
  Instrument,
} from '@tombatossals/react-chords/lib/Chord';

import guitar from '@tombatossals/chords-db/lib/guitar.json';
import ukulele from '@tombatossals/chords-db/lib/ukulele.json';
import {
  Anchor,
  Box,
  Group,
  MantineFontSize,
  Popover,
  Text,
  Title,
  useComputedColorScheme,
} from '@mantine/core';
import { useEffect, useState } from 'react';

const getInstrumentAndChords = (
  chord: string,
  instrument: 'guitar' | 'ukulele',
) => {
  let instrumentDetail: Instrument;
  let chordDetails: ChordDescription[] = [];

  switch (instrument) {
    case 'guitar':
      instrumentDetail = {
        fretsOnChord: guitar.main.fretsOnChord,
        keys: guitar.keys,
        name: guitar.main.name,
        strings: guitar.main.strings,
        tunings: guitar.tunings,
      };
      break;
    case 'ukulele':
      instrumentDetail = {
        fretsOnChord: ukulele.main.fretsOnChord,
        keys: ukulele.keys,
        name: ukulele.main.name,
        strings: ukulele.main.strings,
        tunings: ukulele.tunings,
      };
      break;
  }
  chord = chord.replaceAll(/[^\w\d/]/g, '');

  let key = chord.charAt(0);
  let suffix = chord.slice(1);
  if (chord.charAt(1) === 'b' || chord.charAt(1) === '#') {
    key += chord.charAt(1);
    suffix = chord.slice(2);
  }

  if (suffix === 'M' || suffix === '') {
    suffix = 'major';
  }
  if (suffix === 'm') {
    suffix = 'minor';
  }
  if (suffix.endsWith('sus')) {
    suffix += '4'; // Assume sus4 if its just sus
  }
  if (suffix.startsWith('2') || suffix.startsWith('4')) {
    suffix = 'sus' + suffix;
  }

  if (instrument === 'guitar') {
    if (guitar.keys.includes(key)) {
      const keyOfChords = key as keyof typeof guitar.chords;
      const chords = guitar.chords[keyOfChords];
      let db = chords.find((chord) => chord.suffix === suffix);

      if (!db && suffix.includes('/')) {
        // Try finding one without base note
        suffix = suffix.slice(0, suffix.indexOf('/'));
        db = chords.find((chord) => chord.suffix === suffix);
      }

      if (db) {
        chordDetails = db.positions.map((pos) => ({
          barres: pos.barres,
          baseFret: pos.baseFret,
          capo: pos.capo,
          fingers: pos.fingers,
          frets: pos.frets,
          midi: pos.midi,
        }));
      }
    }
  }

  return {
    chord: key + suffix,
    chords: chordDetails,
    instrument: instrumentDetail,
  };
};

type Props = {
  chord: string;
  size: MantineFontSize;
  instrument?: 'guitar' | 'ukulele';
};

export const ChordChip = ({ chord, instrument = 'guitar', size }: Props) => {
  const [actualChord, setActualChord] = useState<string>('');
  const [chords, setChords] = useState<ChordDescription[]>([]);
  const [instr, setInstr] = useState<Instrument>();

  const colorScheme = useComputedColorScheme('light');

  useEffect(() => {
    const details = getInstrumentAndChords(chord, instrument);
    setChords(details.chords);
    setInstr(details.instrument);
    setActualChord(details.chord);
  }, [chord, instrument]);

  return (
    <Popover withArrow position="top">
      <Popover.Target>
        <Anchor c="inherit" component="button" type="button" size={size}>
          <Text fw="bold">{chord}</Text>
        </Anchor>
      </Popover.Target>
      <Popover.Dropdown
        style={{
          backgroundColor: colorScheme === 'dark' ? 'lightgray' : undefined,
        }}
      >
        <Title order={6} c="dark">
          Chord positions for {actualChord}
        </Title>
        {chords.length === 0 && (
          <Text c="dimmed">
            <i>No positions found</i>
          </Text>
        )}
        <Group>
          {chords.map((chord) => (
            <Box maw={180} key={Math.random()}>
              <Chord chord={chord} instrument={instr} />
            </Box>
          ))}
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
};
