import {
  useCombobox,
  Pill,
  Combobox,
  Group,
  CheckIcon,
  PillsInput,
} from '@mantine/core';
import { useState } from 'react';

type Props = {
  label?: string;
  description?: string;
  placeholder?: string;
  data: { value: string; label: string }[];
  onCreate: (query: string) => { value: string; label: string };
  value?: { value: string; label: string }[];
  onChange?: (update: { value: string; label: string }[]) => void;
  creatable?: boolean;
};

export const CreatableMultiSelect = ({
  creatable = true,
  data: propsData,
  description,
  label,
  onChange,
  onCreate,
  placeholder,
  value: propsValue,
}: Props) => {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');
  const [data, setData] = useState(propsData);
  const [value, setValue] = useState<{ value: string; label: string }[]>(
    propsValue ?? [],
  );

  const exactOptionMatch = data.some((item) => item.label === search);

  const handleValueSelect = (val: string) => {
    if (val === '$create' && creatable) {
      const newValue = { label: search, value: search };
      setData((current) => [...current, newValue]);
      setValue((current) => [...current, newValue]);
      if (onChange) {
        onChange([...value, newValue]);
      }
      onCreate(search);
      setSearch('');
    } else {
      setValue((current) => {
        if (current.find((v) => v.value === val))
          return current.filter((v) => v.value !== val);
        else {
          const dataItem = data.find((d) => d.value === val);
          if (dataItem) {
            return [...current, dataItem];
          }
          return current;
        }
      });
    }
  };

  const handleValueRemove = (val: string) =>
    setValue((current) => current.filter((v) => v.value !== val));

  const values = value.map((item) => (
    <Pill
      key={item.value}
      withRemoveButton
      onRemove={() => handleValueRemove(item.value)}
    >
      {item.label}
    </Pill>
  ));

  const options = data
    .filter((item) =>
      item.value.toLowerCase().includes(search.trim().toLowerCase()),
    )
    .map((item) => (
      <Combobox.Option
        value={item.value}
        key={item.value}
        active={!!value.find((v) => v.value === item.value)}
      >
        <Group gap="sm">
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item.label}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <Combobox
      store={combobox}
      onOptionSubmit={handleValueSelect}
      withinPortal={false}
    >
      <Combobox.DropdownTarget>
        <PillsInput
          label={label}
          description={description}
          onClick={() => combobox.openDropdown()}
        >
          <Pill.Group>
            {values}

            <Combobox.EventsTarget>
              <PillsInput.Field
                onFocus={() => combobox.openDropdown()}
                onBlur={() => combobox.closeDropdown()}
                value={search}
                placeholder={placeholder ?? 'Search values'}
                onChange={(event) => {
                  combobox.updateSelectedOptionIndex();
                  setSearch(event.currentTarget.value);
                }}
                onKeyDown={(event) => {
                  if (event.key === 'Backspace' && search.length === 0) {
                    event.preventDefault();
                    handleValueRemove(value[value.length - 1].value);
                  }
                }}
              />
            </Combobox.EventsTarget>
          </Pill.Group>
        </PillsInput>
      </Combobox.DropdownTarget>

      <Combobox.Dropdown>
        <Combobox.Options>
          {options}

          {creatable && !exactOptionMatch && search.trim().length > 0 && (
            <Combobox.Option value="$create">+ Create {search}</Combobox.Option>
          )}

          {exactOptionMatch &&
            search.trim().length > 0 &&
            options.length === 0 && (
              <Combobox.Empty>Nothing found</Combobox.Empty>
            )}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
};
