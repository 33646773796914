import React, { createContext, useContext } from 'react';
import { useAuth } from '../hooks/use-auth';

export const AuthContext = createContext<ReturnType<typeof useAuth>>({
  refreshSession: async () => false,
  signIn: async (username, password) => false,
  signOut: async () => {
    return;
  },
  user: null,
});
export const useAuthContext = () => useContext(AuthContext);

type Props = {
  children: React.ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const auth = useAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
