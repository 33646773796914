import { Button, LoadingOverlay, Modal, Select, Stack } from '@mantine/core';
import { FormEventHandler, useEffect, useState } from 'react';
import { SigmaApi } from '../../api/api';
import { SetDto, UserDto } from '../../api/openapi';

type Props = {
  set: SetDto;
  refresh?: () => Promise<void>;
};

export const TransferSetToModal = ({ refresh, set }: Props) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState<string | null>(null);
  const [users, setUsers] = useState<UserDto[]>([]);

  const handleTransferOwnership: FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    event.preventDefault();
    if (username) {
      setLoading(true);
      await SigmaApi.sets.setControllerChangeSetOwner(set.id, {
        ownerUsername: username,
      });
      if (refresh) await refresh();
      setLoading(false);
      setOpen(false);
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const response = await SigmaApi.users.usersControllerGetUsers();
      setUsers(response.data);
    };
    getUsers();
  }, [setUsers]);

  return (
    <>
      <Button variant="outline" onClick={() => setOpen(true)}>
        Transfer to
      </Button>
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        title="Transfer ownership"
      >
        <LoadingOverlay visible={loading} />
        <form onSubmit={handleTransferOwnership}>
          <Stack>
            <Select
              label="User"
              searchable
              required
              data={users
                .filter((user) => user.id !== set.owner.id)
                .map((user) => ({
                  label: `${user.name} (${user.username})`,
                  value: user.username,
                }))}
              value={username}
              onChange={(value) => setUsername(value)}
            />
            <Button type="submit">Transfer</Button>
          </Stack>
        </form>
      </Modal>
    </>
  );
};
