import { Button, Stack, Modal } from '@mantine/core';
import { useState } from 'react';
import {
  SongDtoPreferredKeyEnum,
  SongFileDtoMusicTypeEnum,
} from '../../api/openapi';
import { KeySelector } from './KeySelector';
import { MusicTypeSelector } from './MusicTypeSelector';

type Props = {
  fileKey?: SongDtoPreferredKeyEnum;
  setKey: any;
  musicType?: SongFileDtoMusicTypeEnum;
  setMusicType: any;
  onSave: () => Promise<any>;
};

export const EditSongFileDetails = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const onSave = async () => {
    await props.onSave();
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="transparent"
        onClick={() => setOpen(true)}
        style={{ padding: 0 }}
      >
        Edit File
      </Button>
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        title={`Update file details`}
      >
        <Stack gap="xs">
          <KeySelector
            label="Key"
            value={props.fileKey}
            setValue={props.setKey}
          />
          <MusicTypeSelector
            label="Type"
            value={props.musicType}
            setValue={props.setMusicType}
          />
          <Button fullWidth onClick={onSave}>
            Save
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
