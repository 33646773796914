import {
  ColorSwatch,
  Group,
  Stack,
  useMantineTheme,
  Text,
} from '@mantine/core';
import { IconCheck } from '@tabler/icons';

type Props = {
  value?: string;
  setValue: any;
  label?: string;
};

export const ColorSelect = ({ label = '', setValue, value }: Props) => {
  const theme = useMantineTheme();
  return (
    <Stack>
      <Text size="sm">{label}</Text>
      <Group gap="xs">
        {Object.keys(theme.colors).map((color) => (
          <ColorSwatch
            color={theme.colors[color][5]}
            component="button"
            value={color}
            onClick={() => setValue(color)}
            type="button"
            style={{ color: 'white', cursor: 'pointer' }}
          >
            {value === color && <IconCheck />}
          </ColorSwatch>
        ))}
      </Group>
    </Stack>
  );
};
