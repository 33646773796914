import { useEffect, useState } from 'react';
import { CreatableMultiSelect } from './CreatableMultiSelect';

type Props = {
  value?: string[];
  onChange?: React.Dispatch<React.SetStateAction<string[]>>;
};

export const AltTitlesEdit = ({ onChange, value }: Props) => {
  const [data, setData] = useState<{ value: string; label: string }[]>(
    value ? value.map((v) => ({ label: v, value: v })) : [],
  );
  const [selected, setSelected] = useState<{ value: string; label: string }[]>(
    value ? value.map((v) => ({ label: v, value: v })) : [],
  );

  useEffect(() => {
    if (value) {
      setSelected(value.map((v) => ({ label: v, value: v })));
      setData(value.map((v) => ({ label: v, value: v })));
    }
  }, [value]);

  const handleChange = (value: { value: string; label: string }[]) => {
    if (onChange) {
      onChange(value.map((v) => v.value));
    } else {
      setSelected(value);
    }
  };

  return (
    <CreatableMultiSelect
      label="Other titles"
      placeholder="Add more titles..."
      data={data}
      value={selected}
      onChange={handleChange}
      creatable
      onCreate={(query) => {
        const item = { label: query, value: query };
        setData((old) => [...old, item]);
        return item;
      }}
    />
  );
};
