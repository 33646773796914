import { SongDtoPreferredKeyEnum } from '../api/openapi';

export const KEY_COLOURS = {
  [SongDtoPreferredKeyEnum.A]: 'teal',
  [SongDtoPreferredKeyEnum.AFlat]: 'green',
  [SongDtoPreferredKeyEnum.ASharp]: 'cyan',
  [SongDtoPreferredKeyEnum.B]: 'indigo',
  [SongDtoPreferredKeyEnum.BFlat]: 'blue',
  [SongDtoPreferredKeyEnum.BSharp]: 'violet',
  [SongDtoPreferredKeyEnum.C]: 'orange',
  [SongDtoPreferredKeyEnum.CFlat]: 'yellow',
  [SongDtoPreferredKeyEnum.CSharp]: 'lime',
  [SongDtoPreferredKeyEnum.D]: 'teal',
  [SongDtoPreferredKeyEnum.DFlat]: 'green',
  [SongDtoPreferredKeyEnum.DSharp]: 'cyan',
  [SongDtoPreferredKeyEnum.E]: 'indigo',
  [SongDtoPreferredKeyEnum.EFlat]: 'blue',
  [SongDtoPreferredKeyEnum.ESharp]: 'violet',
  [SongDtoPreferredKeyEnum.F]: 'pink',
  [SongDtoPreferredKeyEnum.FFlat]: 'grape',
  [SongDtoPreferredKeyEnum.FSharp]: 'red',
  [SongDtoPreferredKeyEnum.G]: 'orange',
  [SongDtoPreferredKeyEnum.GFlat]: 'yellow',
  [SongDtoPreferredKeyEnum.GSharp]: 'lime',
  [SongDtoPreferredKeyEnum.Unknown]: 'gray',
};

const getLabelForKey = (key: SongDtoPreferredKeyEnum): string => {
  let label: string = key;
  if (label.includes(' Sharp')) {
    label = label.replace(' Sharp', '#');
  } else if (label.includes(' Flat')) {
    label = label.replace(' Flat', 'b');
  } else if (label === 'unknown') {
    label = '?';
  }
  return label;
};

export type DISPLAY_KEY = {
  value: SongDtoPreferredKeyEnum;
  label: string;
  color: string;
};
export const KEYS: DISPLAY_KEY[] = Object.values(SongDtoPreferredKeyEnum).map(
  (key) => ({
    color: KEY_COLOURS[key],
    label: getLabelForKey(key),
    value: key,
  }),
);

export const KEYS_MAP: { [key: string]: DISPLAY_KEY } = Object.assign(
  {},
  ...KEYS.map((key) => ({
    [key.value]: key,
  })),
);

export const KEYS_IN_ORDER: SongDtoPreferredKeyEnum[][] = [
  [SongDtoPreferredKeyEnum.C, SongDtoPreferredKeyEnum.BSharp],
  [SongDtoPreferredKeyEnum.CSharp, SongDtoPreferredKeyEnum.DFlat],
  [SongDtoPreferredKeyEnum.D],
  [SongDtoPreferredKeyEnum.DSharp, SongDtoPreferredKeyEnum.EFlat],
  [SongDtoPreferredKeyEnum.E, SongDtoPreferredKeyEnum.FFlat],
  [SongDtoPreferredKeyEnum.ESharp, SongDtoPreferredKeyEnum.F],
  [SongDtoPreferredKeyEnum.FSharp, SongDtoPreferredKeyEnum.GFlat],
  [SongDtoPreferredKeyEnum.G],
  [SongDtoPreferredKeyEnum.GSharp, SongDtoPreferredKeyEnum.AFlat],
  [SongDtoPreferredKeyEnum.A],
  [SongDtoPreferredKeyEnum.ASharp, SongDtoPreferredKeyEnum.BFlat],
  [SongDtoPreferredKeyEnum.B, SongDtoPreferredKeyEnum.CFlat],
];
