import {
  Button,
  Group,
  MultiSelect,
  Select,
  Space,
  Stack,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import { AxiosError } from 'axios';
import { useState, useCallback, useEffect } from 'react';
import { SigmaApi } from '../../api/api';
import { SongDto } from '../../api/openapi';

export const MergeSongsForm = ({ disabled = false }: { disabled: boolean }) => {
  const [songs, setSongs] = useState<SongDto[]>();

  const [primarySong, setPrimarySong] = useState<string | null>(null);
  const [otherSongs, setOtherSongs] = useState<string[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const getSongs = useCallback(async () => {
    const response = await SigmaApi.songs.songsControllerListSongs();
    setSongs(response.data);
  }, []);

  useEffect(() => {
    getSongs();
  }, [getSongs]);

  const mergeSongs = async () => {
    setLoading(true);
    try {
      if (!primarySong) throw new Error('Primary song is required');
      if (otherSongs.length === 0)
        throw new Error('There must be at least one other song');
      const primaryId = parseInt(primarySong);
      const otherIds = otherSongs.map((o) => parseInt(o));
      await SigmaApi.admin.adminControllerMergeSongs({
        otherIds,
        primaryId,
      });
      showNotification({
        message: `:)`,
        title: 'Songs successfully merged!',
      });
      setPrimarySong(null);
      setOtherSongs([]);
      getSongs();
    } catch (err) {
      let error = 'Unknown';
      if (err instanceof Error) {
        error = err.message;
      }
      if (err instanceof AxiosError) {
        error = JSON.stringify(err.response?.data);
      }
      showNotification({
        color: 'red',
        message: `ERROR: ${error}`,
        title: 'Failed to merge songs.',
      });
    }
    setLoading(false);
  };

  return (
    <Stack>
      <Title order={4}>Merge Songs</Title>
      {songs && (
        <Group grow align="end">
          <Select
            label="Primary Song"
            disabled={disabled}
            data={songs.map((s) => ({
              label: s.title,
              value: s.id.toString(),
            }))}
            searchable
            value={primarySong}
            onChange={(e) => setPrimarySong(e)}
          />
          <MultiSelect
            label="Songs to merge into primary song"
            disabled={disabled}
            data={songs
              .filter((s) => s.id.toString() !== primarySong)
              .map((s) => ({
                label: s.title,
                value: s.id.toString(),
              }))}
            searchable
            value={otherSongs}
            onChange={(e) => setOtherSongs(e)}
          />
          <Button loading={loading} disabled={disabled} onClick={mergeSongs}>
            MERGE
          </Button>
        </Group>
      )}
      <Space h="xl" />
    </Stack>
  );
};
