import { Alert, Button, Group, Stack, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import { useAuthContext } from '../../context/auth-context';
import ErrorAlert from '../atoms/ErrorAlert';

type Props = {
  onSuccess?: () => void;
};
export default function UpdatePassword({ onSuccess }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');

  const auth = useAuthContext();

  useEffect(() => {
    if (auth.user) {
      setOldPassword('');
      setNewPassword('');
      setSuccess(false);
      setError(false);
      setLoading(false);
    }
  }, [auth.user]);

  if (!auth.user) {
    return <Navigate to="/login" />;
  }

  const changePassword: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    try {
      await SigmaApi.auth.authControllerChangePassword({
        newPassword,
        oldPassword,
      });
      setSuccess(true);
      if (onSuccess) onSuccess();
      await auth.refreshSession();
    } catch (error) {
      setError(true);
    }
    setLoading(false);
  };

  return (
    <form onSubmit={changePassword}>
      <input
        name="username"
        type="text"
        value={auth.user.username}
        autoComplete="username"
        readOnly
        style={{ display: 'none' }}
      />
      <Stack>
        <Group grow>
          <TextInput
            label="Current Password"
            required
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            type="password"
            autoComplete="current-password"
          />
          <TextInput
            label="New Password"
            required
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            autoComplete="new-password"
          />
        </Group>
        <Group justify="right">
          {success && <Alert style={{ flexGrow: 1 }}>Password updated</Alert>}
          {error && (
            <ErrorAlert style={{ flexGrow: 1 }}>
              Failed to update password
            </ErrorAlert>
          )}
          <Button loading={loading} type="submit">
            Update password
          </Button>
        </Group>
      </Stack>
    </form>
  );
}
