type Props = {
  songName: string;
  embedId: string;
  style?: React.CSSProperties;
};

export const YoutubeEmbed = ({ embedId, songName, style }: Props) => {
  return (
    <iframe
      style={{ border: 0, ...style }}
      title={`Preview for ${songName}`}
      src={`https://www.youtube.com/embed/${embedId}`}
    />
  );
};
