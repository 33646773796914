import {
  Button,
  Container,
  Group,
  LoadingOverlay,
  Stack,
  TextInput,
  Title,
  UnstyledButton,
} from '@mantine/core';
import { hideNotification, showNotification } from '@mantine/notifications';
import { IconMusic } from '@tabler/icons';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SigmaApi } from '../../api/api';
import { SongDtoPreferredKeyEnum, SongTagDto } from '../../api/openapi';
import { AltTitlesEdit } from '../atoms/AltTitlesEdit';
import ErrorAlert from '../atoms/ErrorAlert';
import { TagsInput } from '../atoms/TagsInput';
import { FileDropzone, SongFile } from '../molecules/FileDropzone';
import { KeySelector } from '../molecules/KeySelector';

export const AddSongPage = () => {
  const [title, setTitle] = useState<string>('');
  const [altTitles, setAltTitles] = useState<string[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [preferredKey, setPreferredKey] = useState<SongDtoPreferredKeyEnum>(
    SongDtoPreferredKeyEnum.Unknown,
  );
  const [files, setFiles] = useState<SongFile[]>([]);
  const [youtubeVideoId, setYoutubeVideoId] = useState<string>('');
  const navigate = useNavigate();

  const [existingTags, setExistingTags] = useState<SongTagDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const createSong: React.FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(undefined);
    const uploads: File[] = files.map((f) => f.file);
    try {
      const response = await SigmaApi.songs.songsControllerUploadNewSong(
        title,
        altTitles,
        tags,
        uploads,
        preferredKey,
        '',
        JSON.stringify(files),
        youtubeVideoId.length > 0 ? youtubeVideoId : undefined,
      );

      setTitle('');
      setAltTitles([]);
      setTags([]);
      setPreferredKey(SongDtoPreferredKeyEnum.Unknown);
      setFiles([]);
      setYoutubeVideoId('');
      const song = response.data;
      showNotification({
        autoClose: 1000 * 10, // 10 seconds
        icon: <IconMusic size={20} />,
        id: `create-${song.id}`,
        message: 'Click above to view the song',
        title: (
          <UnstyledButton
            onClick={() => {
              hideNotification(`create-${song.id}`);
              navigate(`/songs/${song.id}`);
            }}
          >
            {song.title} created
          </UnstyledButton>
        ),
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        setError(`Error: ${err.response?.data}`);
      }
    }
    setLoading(false);
  };

  const onDrop = useCallback(
    (files: File[]) => {
      for (const file of files) {
        const songNameFromFile = file.name.split('.')[0];
        if (title.length === 0) {
          setTitle(songNameFromFile);
        } else if (
          title !== songNameFromFile &&
          !altTitles.includes(songNameFromFile)
        ) {
          // Add an alt title if its not already there
          setAltTitles((old) => [...old, songNameFromFile]);
        }
      }
    },
    [title, altTitles],
  );

  useEffect(() => {
    async function getTags() {
      const response = await SigmaApi.songs.songsControllerGetAllTags();
      setExistingTags(response.data);
    }
    getTags();
  }, []);

  return (
    <Container size="md">
      <LoadingOverlay visible={loading} />
      <form onSubmit={createSong}>
        <Stack>
          <Title order={4}>Add a new song</Title>
          <Stack>
            <FileDropzone onDrop={onDrop} value={files} onChange={setFiles} />
            <Group grow>
              <TextInput
                required
                label="Primary Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <KeySelector
                label="Preferred Key"
                value={preferredKey}
                setValue={setPreferredKey}
              />
              <TextInput
                label="Youtube video id"
                value={youtubeVideoId}
                onChange={(e) => setYoutubeVideoId(e.target.value)}
              />
            </Group>
            <Group grow>
              <AltTitlesEdit value={altTitles} onChange={setAltTitles} />
              <TagsInput
                existingTags={existingTags}
                value={tags}
                onChange={setTags}
                creatable
              />
            </Group>
            <Button type="submit">Create Song</Button>
            {error && <ErrorAlert>{error}</ErrorAlert>}
          </Stack>
        </Stack>
      </form>
    </Container>
  );
};
