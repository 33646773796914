import { SongFileDtoMusicTypeEnum } from '../api/openapi';

export const MUSIC_TYPE_COLOURS = {
  [SongFileDtoMusicTypeEnum.Choral]: 'orange',
  [SongFileDtoMusicTypeEnum.Chord]: 'blue',
  [SongFileDtoMusicTypeEnum.Lead]: 'red',
  [SongFileDtoMusicTypeEnum.Lyrics]: 'green',
  [SongFileDtoMusicTypeEnum.Other]: 'yellow',
  [SongFileDtoMusicTypeEnum.Unknown]: 'gray',
};

const getLabelForMusicType = (key: SongFileDtoMusicTypeEnum): string => {
  let label: string = key;
  if (label === 'unknown') {
    label = '?';
  } else {
    label = label[0].toUpperCase() + label.substring(1);
  }
  return label;
};

export type DISPLAY_MUSIC_TYPE = {
  value: SongFileDtoMusicTypeEnum;
  label: string;
  color: string;
};
export const MUSIC_TYPES: DISPLAY_MUSIC_TYPE[] = Object.values(
  SongFileDtoMusicTypeEnum,
).map((type) => ({
  color: MUSIC_TYPE_COLOURS[type],
  label: getLabelForMusicType(type),
  value: type,
}));

export const MUSIC_TYPES_MAP: { [key: string]: DISPLAY_MUSIC_TYPE } =
  Object.assign(
    {},
    ...MUSIC_TYPES.map((type) => ({
      [type.value]: type,
    })),
  );
