import React, { ReactNode } from 'react';
import { HomePage } from './components/pages/HomePage';
import {
  IconHammer,
  IconHome,
  IconList,
  IconListDetails,
  IconMusic,
  IconPlayerRecord,
  IconPlaylistAdd,
  IconShieldLock,
  IconUser,
} from '@tabler/icons';
import { SongsPage } from './components/pages/SongsPage';
import { SongPage } from './components/pages/SongPage';
import AdminPage from './components/pages/AdminPage';
import AccountPage from './components/pages/AccountPage';
import { AddSongPage } from './components/pages/AddSongPage';
import { SetsPage } from './components/pages/SetsPage';
import { CreateSetPage } from './components/pages/CreateSetPage';
import { SetPage } from './components/pages/SetPage';
import { CollectionPage } from './components/pages/CollectionPage';

interface IRoute {
  menu?: boolean;
  menuOnSelect?: boolean;
  hasParam?: boolean;
  ignoreParamMatch?: string[];
  useTitle?: boolean;
  path: string;
  label?: string;
  icon?: ReactNode;
  element: ReactNode;
  children?: IRoute[];
  admin?: boolean;
}

export const PRIVATE_ROUTES: IRoute[] = [
  {
    element: <HomePage />,
    icon: <IconHome />,
    label: 'Home',
    path: '',
  },
  {
    children: [
      {
        element: <AddSongPage />,
        icon: <IconPlaylistAdd />,
        label: 'Add New',
        menu: true,
        path: 'add',
      },
      {
        element: <SongPage />,
        hasParam: true,
        icon: <IconPlayerRecord />,
        ignoreParamMatch: ['shuffle', 'add'],
        path: ':songId',
        useTitle: true,
      },
    ],
    element: <SongsPage />,
    icon: <IconMusic />,
    label: 'Songs',
    menu: true,
    path: 'songs',
  },
  {
    children: [
      {
        element: <CreateSetPage />,
        icon: <IconHammer />,
        label: 'Create New',
        menu: true,
        path: 'create',
      },
      {
        element: <SetPage />,
        hasParam: true,
        icon: <IconListDetails />,
        ignoreParamMatch: ['create'],
        path: ':setId',
        useTitle: true,
      },
    ],
    element: <SetsPage />,
    icon: <IconList />,
    label: 'Sets',
    menu: true,
    path: 'sets',
  },
  {
    element: <CollectionPage />,
    menu: false,
    path: 'collections/:collectionId',
  },
];

export const USER_ROUTES: IRoute[] = [
  {
    element: <AccountPage />,
    icon: <IconUser />,
    label: 'My Account',
    menu: true,
    path: 'me',
  },
  {
    admin: true,
    element: <AdminPage />,
    icon: <IconShieldLock />,
    label: 'Admin',
    menu: true,
    path: 'admin',
  },
];
