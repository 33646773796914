import { NavLink, Transition } from '@mantine/core';
import { IconPlaylist } from '@tabler/icons';
import { Link, useLocation } from 'react-router-dom';
import { useOpenSongsContext } from '../../context/open-songs.context';

type Props = {
  callback?: () => void;
};

export const OpenSongLinks = ({ callback }: Props) => {
  const { songs } = useOpenSongsContext();
  const location = useLocation();
  return (
    <NavLink
      label="Open Songs"
      description={`${songs.length}/5`}
      defaultOpened
      childrenOffset={28}
      leftSection={<IconPlaylist />}
    >
      {songs.map((song) => {
        const matches = location.pathname === `/songs/${song.song.id}`;

        return (
          <Transition
            mounted={true}
            transition="slide-right"
            duration={400}
            timingFunction="ease"
            key={song.song.id}
          >
            {(styles) => (
              <div key={song.song.id} style={styles}>
                <NavLink
                  key={song.song.id}
                  label={song.song.title}
                  component={Link}
                  to={`/songs/${song.song.id}`}
                  active={matches}
                  variant={'light'}
                  onClick={callback}
                ></NavLink>
              </div>
            )}
          </Transition>
        );
      })}
    </NavLink>
  );
};
