import { useEffect, useState } from 'react';
import { SigmaApi } from '../api/api';
import { UserDto } from '../api/openapi';

export const useAuth = () => {
  const [user, setUser] = useState<UserDto | null>(null);

  const signIn = async (username: string, password: string) => {
    try {
      const response = await SigmaApi.auth.authControllerLogin({
        password,
        username,
      });
      setUser(response.data);
      return true;
    } catch (err) {
      return false;
    }
  };

  const signOut = async () => {
    await SigmaApi.auth.authControllerLogout();
    setUser(null);
  };

  const refreshSession = async () => {
    try {
      const response = await SigmaApi.users.usersControllerGetMe();
      setUser(response.data);
      return true;
    } catch (err) {
      return false;
    }
  };

  // On load try to get session
  useEffect(() => {
    refreshSession();
  }, []);

  return { refreshSession, signIn, signOut, user };
};
